// common.scss
// ANIMATION
@mixin animation($name, $duration, $delay, $count, $direction, $ease) {
  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $name;
  animation-iteration-count: $count;
  animation-direction: $direction;
  animation-timing-function: $ease;
}

@mixin keyframe($animation_name) {
  @keyframes #{$animation_name} {
    @content;
  }
}

// LAYOUT
* {
  letter-spacing: -0.02rem;
  font-family: var(--bs-body-font-family);
}
html {
  font-size: 16px;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--bs-dark);
  // cursor: none;
  // overflow: hidden;
  /* Prevent font scaling in landscape */
  -webkit-text-size-adjust: none; /*Chrome, Safari, newer versions of Opera*/
  -moz-text-size-adjust: none; /*Firefox*/
  -ms-text-size-adjust: none; /*Ie*/
  -o-text-size-adjust: none; /*old versions of Opera*/
}
a {
  color: var(--bs-primary);
  font-weight: 500;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}
small {
  font-weight: 500;
  color: var(--bs-gray-500);
}
ul {
  padding-left: 0;
  margin: 0;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
  margin: 0;
}
button {
  border: 0;
  outline: 0;
}
dl,
dd {
  margin: 0;
}
video {
  width: 100%;
}
::selection {
  background: var(--bs-gray-300);
  color: var(--bs-dark);
}

.container {
  position: relative;
  width: 1280px;
  max-width: 1280px;
  padding: 0;
}
.contents {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  main {
    // padding: 180px 0;
  }
}

// APP-CONTAINER
.app-container {
  padding: 0;
  // 스크롤 탑일 때
  &.scroll-top {
    header {
      background: transparent;
      box-shadow: none;
    }
  }

  // 스크롤 내려갈 때
  &.scroll-down {
    header {
      top: -150px;
    }
  }

  // 화이트 헤드일 때
  &.white-header {
    header {
      .container {
        svg {
          * {
            fill: var(--bs-white);
          }
        }
        .btn_menu {
          svg {
            rect {
              fill: var(--bs-white);
            }
          }
        }
        .menu {
          .menu-list {
            & > li {
              a {
                color: var(--bs-white) !important;
              }
            }
          }
        }
      }
    }
  }

  // 메뉴 열렸을 때
  &.open-menu {
    .btn_menu {
      svg {
        rect {
          fill: var(--bs-primary-dark) !important;
          &:nth-of-type(1) {
            transform: translate(-1px, 23px) rotate(-45deg);
          }
          &:nth-of-type(2) {
            opacity: 0;
          }
          &:nth-of-type(3) {
            transform: translate(-1px, -23px) rotate(45deg);
          }
        }
      }
      &:before {
        opacity: 0.95;
        transform: scale(1);
      }
    }
  }
}

// HEADER
header {
  width: 100%;
  height: 110px;
  transition: ease 0.25s;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  background: var(--bs-white);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      * {
        transition: 0.5s cubic-bezier(0.16, 0.9, 0.72, 0.97);
      }
    }
    .logo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .logoText {
        margin-left: 20px;
        position: relative;
        // left: -40px;
        // opacity: 0;
        // visibility: hidden;
        transition: 0.5s cubic-bezier(0.16, 0.9, 0.72, 0.97);
      }
    }

    // 태블릿, 모바일 삼선버튼
    .btn_menu {
      border: 0;
      padding: 0;
      background: transparent;
      position: relative;
      display: none;
      svg {
        position: relative;
        width: 36px;
        height: 36px;
        rect {
          width: 100%;
          height: 3px;
          fill: var(--bs-dark);
          x: 0;
          &:nth-of-type(1) {
            y: 9px;
          }
          &:nth-of-type(2) {
            y: 17px;
          }
          &:nth-of-type(3) {
            y: 25px;
            transform-origin: left bottom;
          }
        }
      }
      &:before {
        content: "";
        display: block;
        width: 8vw;
        height: 8vw;
        border-radius: 100%;
        background: #eee;
        box-shadow: inset -1px -1px 1px rgba(194, 194, 194, 0.7), inset 1.5px 1.5px 1px #f9f9f9;
        position: absolute;
        top: calc(50% - 4vw);
        left: calc(50% - 4vw);
        opacity: 0;
        transform-origin: center;
        transform: scale(0);
        transition: 0.5s cubic-bezier(0.16, 0.9, 0.72, 0.97);
      }
    }
  }
}

// MENU
.menu {
  display: block;
  z-index: 101;
  .menu-list {
    display: flex;
    position: relative;
    align-items: flex-start;
    & > li {
      a {
        font-size: 1.25rem;
        letter-spacing: 0.04rem;
        font-weight: 600;
        text-transform: uppercase;
        transition: 0.2s ease-in-out;
        &.active {
          opacity: 1;
          font-weight: 800;
        }
      }
    }
  }
  &#menu-pc {
    .menu-list {
      & > li {
        margin-left: 3rem;
        position: relative;
        a {
          opacity: 0.85;
          color: var(--bs-dark);
          &.active {
            opacity: 1;
          }
        }
        .submenu-wrap {
          position: absolute;
          top: 2rem;
          left: 0;
          padding: 1rem 0;
          opacity: 0;
          visibility: hidden;
          transition: 0.2s ease-in-out;
          ul {
            padding: 0.5rem 0;
            background: var(--bs-white);
            border-radius: 8px;
            width: 160px;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
            li {
              a {
                display: block;
                padding: 0.5rem 1rem;
                font-size: 1rem;
                font-weight: 600;
                color: var(--bs-dark) !important;
                opacity: 0.8;
                transition: 0.2s ease-in-out;
                &.active {
                  opacity: 1;
                  font-weight: 800;
                }
              }
            }
          }
        }
      }
    }
  }
  &#menu-mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 95%;
    height: 100%;
    background: var(--bs-white);
    transform: translateX(-100%);
    transition: 0.5s cubic-bezier(0.16, 0.9, 0.72, 0.97);
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    .menu-list {
      flex-direction: column;
      padding: 5rem 1.5rem;
      height: 100%;
      & > li {
        margin-top: 1rem;
        width: 100%;
        a {
          font-size: 1.2rem;
          display: block;
          font-weight: 500;
          color: var(--bs-dark);
          &.active {
            font-weight: 800;
          }
        }
        .submenu-wrap {
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background: var(--bs-white);
          transition: 0.5s cubic-bezier(0.16, 0.9, 0.72, 0.97);
          visibility: hidden;
          padding: 6rem 1.5rem;
          box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
          li {
            margin-top: 1rem;
            width: 100%;
            a {
              display: block;
              font-weight: 500;
              color: var(--bs-dark);
              &.active {
                font-weight: 800;
              }
            }
          }
        }
        &.has-submenu {
          background: url("../images/ic_arrow_submenu.png") no-repeat center right / 7px auto;
          .submenu-wrap {
            h3 {
              font-weight: 800;
              font-size: 1.25rem;
              letter-spacing: 0.04rem;
              padding-left: 1.5rem;
              background: url("../images/ic_arrow_submenu_back.png") no-repeat center left / 7px auto;
            }
            .submenu {
              margin: 1.5rem 0 0;
            }
          }
          &.show {
            .submenu-wrap {
              left: 0;
              visibility: visible;
            }
          }
        }
      }
    }
  }
}
.menu-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.5s cubic-bezier(0.16, 0.9, 0.72, 0.97);
  opacity: 0;
  visibility: hidden;
  z-index: 100;
}
// FOOTER
footer {
  width: 100%;
  padding: 60px 0;
  background-color: var(--bs-black);
  position: relative;
  z-index: 100;
  * {
    letter-spacing: 0;
    color: var(--bs-white);
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--bs-gray-600);
    margin-bottom: 2rem;
    .logo {
      display: inline-flex;
      align-items: center;
      svg {
        * {
          fill: var(--bs-white);
        }
        &:last-of-type {
          margin-left: 20px;
        }
      }
    }
    .sns-list {
      display: inline-flex;
      li {
        margin-left: 2.2rem;
        opacity: 0.8;
        transition: 0.2s ease-in-out;
        a {
          display: block;
          width: 36px;
          height: 36px;
          background-repeat: no-repeat;
          background-size: auto 26px;
          background-position: center;
          &.fb {
            background-image: url("../images/sns_fb.png");
          }
          &.tw {
            background-image: url("../images/sns_tw.png");
          }
          &.insta {
            background-image: url("../images/sns_insta.png");
          }
          &.blog {
            background-image: url("../images/sns_blog.png");
          }
          &.tiktok {
            background-image: url("../images/sns_tiktok.png");
          }
          &.youtube {
            background-image: url("../images/sns_youtube.png");
          }
          &.zepeto {
            background-image: url("../images/sns_zepeto.png");
          }
          &.kakao {
            background-image: url("../images/sns_kakao.png");
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    & > div {
      display: flex;
      flex-direction: column;
      &:first-of-type {
        dl {
          &:last-of-type {
            margin-top: 1.5rem;
          }
        }
      }
      &:last-of-type {
        dl {
          dd {
            font-weight: 400;
            font-family: "Noto Sans", "Noto Sans KR", sans-serif;
            line-height: 2.2rem;
            span {
              display: inline-block;
              font-family: "Noto Sans", "Noto Sans KR", sans-serif;
              width: 20px;
              font-weight: 500;
            }
            a {
              text-decoration: underline;
              &:hover {
                color: var(--bs-wthie);
              }
            }
          }
        }
      }
      dl {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        dt {
          font-size: 1rem;
          font-weight: 400;
          margin-bottom: 0.2rem;
        }
        dd {
          font-weight: 500;
          font-size: 1.25rem;
        }
      }
    }
  }
  .copyright {
    display: block;
    margin-top: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.08rem;
    color: var(--bs-gray-500);
    span {
      font-size: 0.95rem;
      font-weight: 400;
      color: var(--bs-gray-500);
    }
  }
}

// GOSCROLLTOP
@function svgIcon($color) {
  $color: "%23" + $color;
  $icon: "data:image/svg+xml,%3Csvg width='24' height='23' viewBox='0 0 24 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7165 1.71606L11.9993 1.43322L12.2822 1.71606L22.2828 11.7167L22.5657 11.9996L22.2828 12.2824L21.3214 13.2439L21.0386 13.5267L20.7557 13.2439L13.0779 5.56732L13.0779 21.9989L13.0779 22.3989L12.6779 22.3989L11.318 22.3989L10.918 22.3989L10.918 21.9989L10.918 5.56879L3.24293 13.2439L2.96009 13.5267L2.67724 13.2439L1.7158 12.2824L1.43295 11.9996L1.7158 11.7167L11.7165 1.71606Z' fill='#{$color}' stroke='#{$color}' stroke-width='0.8'/%3E%3C/svg%3E%0A";
  @return url($icon);
}

#progress-circle {
  position: fixed;
  bottom: 64px;
  right: 64px;
  border-radius: 100px;
  padding: 0;
  border: 0;
  // background: transparent url('../images/btn_goTop.png') no-repeat center;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-image: svgIcon(191919);
  opacity: 1;
  transition: 0.5s cubic-bezier(0.16, 0.9, 0.72, 0.97);
  z-index: 100;
  &.hide {
    opacity: 0;
    transition: 0s;
  }
  &.white {
    background-image: svgIcon(fff);
    svg {
      circle {
        stroke: var(--bs-white);
      }
    }
  }
  svg {
    width: 64px;
    height: 64px;
    transform: rotate(-90deg);
    circle {
      fill: rgba(0, 0, 0, 0);
      stroke: var(--bs-dark);
      stroke-width: 4;
      transition: stroke 0.5s cubic-bezier(0.16, 0.9, 0.72, 0.97);
    }
    .base {
      opacity: 0.2;
    }
    .now {
      stroke-dasharray: 219.911;
    }
  }
}

// CURSOR
#cursor-dot {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  pointer-events: none;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  // background: rgba(255, 255, 255, 0.8);
  background: rgba(255, 255, 255, 0);
  transform: translate(-50%, -50%) scale(1);
}
#cursor-circle {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(1);
  width: 72px;
  height: 72px;
  border-radius: 100%;
  border: 2px solid var(--bs-primary);
  // background: var(--bs-primary);
  mix-blend-mode: hard-light;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
#cursor-wheel {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  pointer-events: none;
  transform: translate(40%, 40%) scale(1);
  width: 56px;
  height: 56px;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
// LOADING
.loading-inner {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  // opacity: 0;
  // visibility: hidden;
  z-index: 110;
  svg {
    height: 56px;
  }
  &.show {
    opacity: 1;
    visibility: visible;
  }
}

// COMMON
// 얇은 타이틀
.title-thin {
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 4rem;
  margin: 0;
  word-break: keep-all;
}
// 굵은 타이틀
.title-bold {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4rem;
  letter-spacing: 0;
  margin: 0;
  word-break: keep-all;
}
// 굵은 타이틀
.title-bold-02 {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 4rem;
  letter-spacing: 0;
  margin: 0;
  word-break: keep-all;
}
// 굵은 타이틀
.title-bold-03 {
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 5rem;
  letter-spacing: 0;
  margin: 0;
}
// 회색 서브 타이틀
.sub-title-gray {
  color: var(--bs-gray);
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.14em;
  margin: 0 0 1rem;
  word-break: keep-all;
}
// 흰색 서브 타이틀
.sub-title-white {
  color: var(--bs-light);
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 500;
  margin-top: 1rem;
  word-break: keep-all;
}

.row {
  min-width: 1px;
}

.listStyle {
  margin-bottom: 2rem;
  li {
    position: relative;
    padding-left: 1rem;
    &:before {
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 10px;
      background: var(--bs-gray-600);
      position: absolute;
      top: calc(50% - 2px);
      left: 0;
    }
  }
}

.noData {
  display: flex;
  min-height: 500px;
  justify-content: center;
  align-items: center;
  color: var(--bs-gray-500);
  font-weight: 300;
}

.txtLine {
  display: block;
  width: 100%;
  height: 90px;
  overflow: hidden;
}

.pin-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .pin-spacer {
    flex: 30% 0 0;
    max-width: 30%;
    .pin-content {
      height: 100vh;
      position: relative;
      & > * {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        visibility: hidden;
        // &:nth-of-type(1){
        //   opacity:1;
        //   visibility: visible;
        // }
      }
      &.noAlpha {
        & > * {
          opacity: 1;
          visibility: inherit;
        }
      }
    }
  }
  .scroll-content {
    flex: 70% 0 0;
    flex-shrink: 0;
    flex-grow: 0;
    max-width: 70%;
  }
}

.dlBox {
  dl {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    dt,
    dd {
      font-size: 1rem;
      font-weight: 500;
      line-height: 2;
      color: var(--bs-gray);
      p {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.6;
        color: var(--bs-gray);
        margin: 0;
        padding: 0.25rem 0;
      }
    }
    dt {
      flex: 80px 0 0;
      flex-shrink: 0;
      color: var(--bs-primary);
    }
    dd {
      flex: 1;
    }
  }
}

.btn-primary {
  color: #fff !important;
}
.btn_link {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.85rem 5rem;
  border-radius: 48px;
  box-shadow: 2px 3px 11px rgba(0, 0, 0, 0.08);
}

.spinner-inner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary);
  &.no-fixed {
    position: relative;
    bottom: inherit;
    left: inherit;
    height: auto;
    padding: 10rem 0;
  }
  &.pt-lg-spinner {
    height: 60vh;
  }
}

// HOVER
@media (min-width: 1025px) {
  @media (hover: hover) {
    .btn-primary {
      &:hover {
        background-color: var(--bs-primary-hover);
      }
    }
    header {
      .container {
        .menu {
          &#menu-pc {
            .menu-list {
              & > li {
                &:hover {
                  a {
                    // font-weight: 700;
                    opacity: 1;
                  }
                }
                .submenu-wrap {
                  li {
                    &:hover {
                      a {
                        opacity: 1;
                      }
                    }
                  }
                }
                &.has-submenu {
                  &:hover {
                    .submenu-wrap {
                      opacity: 1;
                      visibility: visible;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    footer {
      .top {
        .sns-list {
          li {
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
