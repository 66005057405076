// reset.scss
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap");

// Gilroy 폰트
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Gilroy-Light.woff2") format("woff2"), url("../fonts/Gilroy-Light.woff") format("woff");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Gilroy-Regular.woff2") format("woff2"), url("../fonts/Gilroy-Regular.woff") format("woff");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Gilroy-Medium.woff2") format("woff2"), url("../fonts/Gilroy-Medium.woff") format("woff");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Gilroy-Semibold.woff2") format("woff2"), url("../fonts/Gilroy-Semibold.woff") format("woff");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Gilroy-Bold.woff2") format("woff2"), url("../fonts/Gilroy-Bold.woff") format("woff");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/Gilroy-Extrabold.woff2") format("woff2"), url("../fonts/Gilroy-Extrabold.woff") format("woff");
}

// BootStrap Root Reset
$theme-colors: (
  "primary": #326bff,
  "primary-dark": #151f35,
  "primary-light": #afc5ff,
  "primary-hover": #1854ee,
  "dark": #191919,
  "body-color": #191919,
  "bg-blue": #edf1ff,
  "gray": #797979,
  "gray-100": #f5f5f5,
  "gray-200": #eee,
  "gray-300": #ddd,
  "gray-400": #bbb,
  "gray-500": #aaa,
  "border": #bdbdbd,
  "border-blue": #3e4e79,
  "light-blue": #8dadff,
  "thin-blue": #e3ebff,
  "light": #f0f3f8,
  "yellow": #ffe609,
  "black": #000,
);
$font-family-sans-serif: "Gilroy", "Noto Sans KR", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Apple SD Gothic Neo", Arial, sans-serif;
$body-font-size: 16px;
$body-line-height: 1.6;

// 참고
// --bs-blue: #0d6efd;
// --bs-indigo: #6610f2;
// --bs-purple: #6f42c1;
// --bs-pink: #d63384;
// --bs-red: #dc3545;
// --bs-orange: #fd7e14;
// --bs-yellow: #ffc107;
// --bs-green: #198754;
// --bs-teal: #20c997;
// --bs-cyan: #0dcaf0;
// --bs-white: var(--bs-white);
// --bs-gray: #6c757d;
// --bs-gray-dark: #343a40;
// --bs-gray-100: #f8f9fa;
// --bs-gray-200: #e9ecef;
// --bs-gray-300: #dee2e6;
// --bs-gray-400: #ced4da;
// --bs-gray-500: #adb5bd;
// --bs-gray-600: #6c757d;
// --bs-gray-700: #495057;
// --bs-gray-800: #343a40;
// --bs-gray-900: #212529;
// --bs-primary: #0d6efd;
// --bs-secondary: #6c757d;
// --bs-success: #198754;
// --bs-info: #0dcaf0;
// --bs-warning: #ffc107;
// --bs-danger: #dc3545;
// --bs-light: #f8f9fa;
// --bs-dark: #212529;

// --bs-font-sans-serif: "Montserrat", "Noto Sans", sans-serif;
// --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
// --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
// --bs-body-font-family: var(--bs-font-sans-serif);
// --bs-body-font-size: 1rem; // 16px
// --bs-body-font-weight: 400;
// --bs-body-line-height: 1.5;
// --bs-body-color: #212529;
// --bs-body-bg: var(--bs-white);
