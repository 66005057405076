// responsive.scss

// ***********************************
// TABLET
// ***********************************
@media (max-width: 1024px) {
  // *******************
  // common.scss
  // *******************
  html {
    font-size: 2.6vw;
  }

  // GOSCROLLTOP
  #progress-circle {
    bottom: 1.5rem;
    right: 1.5rem;
    background-size: 24px auto;
    svg {
      width: 56px;
      height: 56px;
      circle {
        stroke-width: 3;
      }
    }
  }
  // LOADING
  .loading-inner {
    svg {
      height: 48px;
    }
  }
  .spinner-inner {
    .spinner-border {
      width: 1.2rem;
      height: 1.2rem;
      padding: 0;
      border-width: 3px;
    }
  }

  // COMMON
  // 얇은 타이틀
  .title-thin {
    font-size: 1.3rem;
    line-height: 1.6;
  }
  // 굵은 타이틀
  .title-bold {
    font-size: 2.4rem;
    line-height: 1.6;
  }
  // 굵은 타이틀
  .title-bold-02 {
    font-size: 1.4rem;
    line-height: 1.6;
  }
  // 굵은 타이틀
  .title-bold-03 {
    font-size: 3rem;
  }
  // 회색 서브 타이틀
  .sub-title-gray {
    font-size: 1rem;
    letter-spacing: 0.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  // 흰색 서브 타이틀
  .sub-title-white {
    font-size: 1rem;
    line-height: 1.6;
    letter-spacing: 0.02rem;
    font-weight: 500;
    margin: 0;
  }
  .dlBox {
    dl {
      dt {
        flex: 20vw 0 0;
      }
    }
  }
  .pin-box {
    flex-direction: column;
    .pin-spacer {
      max-width: 100%;
    }
    .scroll-content {
      max-width: 100%;
    }
  }
  .Toastify__toast-container {
    min-width: 50vw;
    top: 5rem;
    left: inherit;
    right: 1.5rem;
    .Toastify__toast {
      margin: 0.5rem 0 0;
    }
  }

  .btn_link {
    font-size: 1rem;
    padding: 0.5rem 2rem;
  }
  // APP-CONTAINER
  .App.smallScreen {
    .app-container {
      // 스크롤 내려갈 때
      &.scroll-down {
        header {
          top: -5rem;
        }
      }

      // 메뉴 열렸을 때
      &.open-menu {
        header {
          background: var(--bs-white);
          box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
          transform: translateY(0) !important;
          .container {
            svg {
              * {
                &.logo_symbol_svg__dark {
                  fill: var(--bs-primary-dark) !important;
                }
                &.logo_symbol_svg__light {
                  fill: var(--bs-primary) !important;
                }
              }
            }
          }
        }
        .menu {
          &#menu-mobile {
            visibility: visible;
            transform: translateX(0);
          }
        }
        .menu-mask {
          opacity: 1;
          visibility: visible;
        }
        .panel.visual,
        .panel.visual section,
        .pin-spacer {
          width: 100vw !important;
        }
      }
    }

    // LAYOUT
    .container {
      max-width: 100%;
      width: 100%;
      padding: 0 1.5rem;
    }
    .contents {
      min-height: 100vh;
    }

    // HEADER
    header {
      height: 4rem;
      .container {
        .logo {
          svg {
            height: 4.5vw;
            min-height: 28px;
            &.logoText {
              display: none;
            }
          }
        }
        .btn_menu {
          display: block;
        }
      }
    }
    // MENU
    .menu {
      &#menu-pc {
        display: none;
      }
      &#menu-mobile {
        display: block;
      }
    }
    // FOOTER
    footer {
      padding: 2rem 0;
      .container {
        padding-bottom: 3rem;
        .top {
          display: block;
          .logo {
            svg {
              &:nth-of-type(1) {
                max-height: 28px;
              }
              &:last-of-type {
                max-height: 16px;
                margin-left: 12px;
              }
            }
          }
          .sns-list {
            position: absolute;
            bottom: 0;
            left: 1.5rem;
            li {
              margin-left: 0;
              margin-right: 1rem;
              a {
                width: 24px;
                height: 24px;
                background-size: auto 20px;
              }
            }
          }
        }
        .bottom {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          & > div {
            &:first-of-type {
              dl {
                &:last-of-type {
                  margin-top: 1rem;
                }
                dt,
                dd {
                  font-size: 0.9rem;
                }
              }
            }
            &:last-of-type {
              width: 100%;
              flex-direction: row;
              flex-wrap: wrap;
              margin-top: 2rem;
              dl {
                flex: 50% 0 0;
                margin-left: 0;
                dd {
                  font-size: 0.9rem;
                  line-height: 1.5rem;
                }
              }
            }
          }
        }
        .copyright {
          margin-top: 3rem;
          font-size: 0.8rem;
          span {
            font-size: 0.8rem;
          }
        }
      }
    }

    // *******************
    // pages.scss
    // *******************
    main {
      section {
        padding: 5rem 0 0;
        &.foot {
          padding: 5rem 0 !important;
          h5 {
            font-size: 1.5rem;
          }
          h6 {
            font-size: 1rem;
            padding: 0.5rem 0 3rem;
          }
          .btn {
            font-size: 1.1rem;
            min-width: 140px;
          }
        }
      }
      &.noVisual {
        padding: 7rem 0 0;
        .container {
          .title-area {
            margin: 0 0 2rem;
            .title-bold-03 {
              font-size: 2.8rem;
              font-weight: 700;
            }
          }
        }
      }
    }

    // HOME (메인)
    #home {
      section {
        min-height: auto;
        .container {
          padding: 0 1.5rem;
        }
        &#visual {
          min-height: 100vh;
          align-items: center;
          .typo {
            h1 {
              font-size: 8vw;
            }
          }
          .effectBox {
            margin-left: 2vw;
          }
          .visualimg {
            margin-top: 2rem;
            align-self: flex-end;
            position: inherit;
            bottom: inherit;
            right: inherit;
            height: auto;
            max-width: 60vw;
            max-height: 34vh;
          }
        }
        .mainTxt {
          .badge {
            font-size: 1.2rem;
            letter-spacing: 0.2rem;
            font-weight: 700;
            margin-bottom: 1rem;
          }
          h2 {
            font-size: 1.8rem;
          }
        }
        .works-box {
          margin-top: 3rem;
          .works {
            padding-bottom: 60vh;
            .ourWorks {
              width: 40vw;
              height: auto;
              right: inherit;
              top: 2vh;
              left: 6vw;
            }
            .card {
              right: calc(50% - 20vw);
              width: 40vw;
              height: 50vw;
            }
          }
        }
        .puzzle {
          margin-top: 5rem;
          padding-bottom: 50vh;
          div {
            min-width: auto;
            width: 86vw;
          }
        }
        &.chart-section {
          .chart {
            margin-top: 2rem;
            svg {
              &.chartBg {
                width: 200vw;
                right: -110vw;
                bottom: -10vh;
              }
              &.chartSvg {
                right: -25vw;
                width: 90vw;
              }
            }
          }
        }
      }
    }

    // ABOUT (회사소개)
    #about {
      .panel {
        &.visual {
          .bg {
            background: url("../images/about/bg_visual_m.png") no-repeat bottom center / cover;
          }
          section {
            .typo {
              margin-top: -28vh;
              .sub-title {
                color: var(--bs-primary-light);
              }
              h1 {
                font-size: 8vw;
                line-height: 1.6;
              }
            }
          }
        }
        &.bottom {
          .bg {
            background: url("../images/about/bg_about_m.png") no-repeat top center;
          }
          section {
            &.business {
              .businessBox {
                margin: 3rem 0 5rem;
              }
            }
            &.partner {
              .partners {
                margin: 3rem 0 5rem;
              }
            }
            &.history {
              .list {
                margin-top: 3rem;
                padding: 0 1.5rem;
                .pin-box {
                  width: 100%;
                  // align-items: center;
                  .pin-content {
                    // display: inline-flex;
                    background: #fff;
                    border-bottom: 1px solid var(--bs-border);
                    z-index: 1;
                    &.noAlpha {
                      .txtLine {
                        margin: 30px 0;
                        height: 60px;
                        h3 {
                          font-size: 5rem;
                          line-height: 60px;
                          span {
                            transform: translateY(-60px);
                            left: 6rem;
                          }
                        }
                      }
                    }
                  }
                  .scroll-content {
                    &.enter {
                      padding-top: 121px;
                      &:after {
                        content: "";
                        display: block;
                        width: 100vw;
                        position: fixed;
                        top: 0;
                        left: 0;
                        height: 120px;
                        background: #fff;
                      }
                    }
                  }
                }
                ul {
                  border-top: 0;
                  border-bottom: 1px solid var(--bs-gray-300);
                  padding: 80px 0;
                  &:last-of-type {
                    border-bottom: 0;
                  }
                  li {
                    font-size: 1.1rem;
                    line-height: 1.6;
                    margin-bottom: 24px;
                    &:last-of-type {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
            &.certifications {
              padding: 5rem 0;
              z-index: 2;
              ul {
                margin-top: 1.5rem;
                li {
                  flex-basis: calc(50% - 10px);
                  margin-right: 20px;
                  &:nth-child(2n) {
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    // SERVICE (서비스)
    .service {
      .panel.visual {
        .container {
          .typo {
            padding-bottom: 26vh;
            .title-bold-03 {
              font-size: 2.8rem;
              line-height: 3rem;
              &.fullname {
                font-size: 1.5rem;
              }
            }
            .title-bold-02 {
              font-size: 1.2rem;
              margin-top: 0.5rem;
            }
            p.sub-title-white {
              font-size: 1rem;
              letter-spacing: 0;
              font-weight: 400;
            }
          }
        }
      }
      .bottom {
        section {
          min-height: auto;
          .typo {
            p {
              font-size: 1rem;
              line-height: 1.6;
              font-weight: 400;
              margin-bottom: 0;
            }
          }
          &.section01 {
            padding: 5rem 0;
            .container {
              ul {
                &.words {
                  padding: 2rem 0 0;
                  width: 300%;
                  margin-left: -15vw;
                  li {
                    span {
                      margin-right: 0;
                      font-size: 2.2rem;
                      line-height: 1.6;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // -- UP NFT
    #svc-nft {
      section {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title-bold-02 {
          font-size: 1.25rem;
          word-break: keep-all;
        }
        .mockup {
          margin: 4rem 0;
        }
      }
    }
    // -- UPTS
    #svc-ts {
      .bottom {
        section {
          &.section02 {
            padding: 5rem 0 3rem;
            .text-center {
              margin: 2vh auto 0;
              width: 36vh;
              height: 36vh;
            }
          }
          &.section03 {
            .container {
              padding: 0;
              .change-panel-wrap-01 {
                padding: 3rem 1.5rem 0 !important;
                .change-panel-list {
                  margin: 0;
                  height: 60vh;
                  article {
                    img {
                      height: 34vh;
                    }
                    p {
                      max-width: 100%;
                      margin: 1.5rem 0 0;
                      padding: 0 3rem;
                      font-size: 1rem;
                    }
                  }
                }
                .keywords {
                  top: 78vh;
                  & > div {
                    width: 6rem;
                    height: 6rem;
                    font-size: 0.95rem;
                    margin: 0 0.25rem;
                  }
                }
              }
            }
          }
          &.section04 {
            .container {
              padding: 0;
              .change-panel-wrap-02 {
                padding: 3rem 1.5rem 0 !important;
                .circle-wrap {
                  height: 52vh;
                  .state {
                    width: 100%;
                    justify-content: center;
                  }
                  .circle {
                    transform: scale(0.8);
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    top: 6vh;
                    left: calc(50% - 20vh);
                    width: 40vh;
                    height: 40vh;
                    strong {
                      font-size: 1.4rem;
                      line-height: 1.4;
                    }
                    small {
                      font-size: 1rem;
                      margin-top: 1rem;
                      font-weight: 400;
                    }
                    &.active {
                      transform: scale(1);
                      opacity: 1;
                      visibility: visible;
                    }
                  }
                  .pagination-wrap {
                    display: flex;
                  }
                }
                .range-area {
                  margin-top: 0;
                  .range-bar {
                    padding: 6px;
                    height: 22px;
                  }
                  .range-text {
                    li {
                      font-size: 0.8rem;
                    }
                  }
                }
              }
            }
          }
          &.section05 {
            padding: 5rem 0;
            .imgBox {
              padding: 20px;
              border-radius: 20px;
              box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
              align-items: flex-end;
              .btn_zoom {
                display: block;
              }
            }
          }
          &.section06 {
            padding: 5rem 0;
          }
          &.section07 {
            padding: 5rem 0;
            .text-center {
              margin: 4vh auto;
              img {
                max-width: 100%;
              }
            }
          }
        }
      }
    }
    // -- UP WALLET
    #svc-wallet {
      .parallax-bg {
        background-position: 20% center;
      }
      .bottom {
        section {
          &.section02 {
            .container {
              padding: 0;
              .change-panel-wrap {
                padding: 3rem 1.5rem 0 !important;
                .change-panel-list {
                  margin: 0;
                  height: 60vh;
                  article {
                    img {
                      height: 30vh;
                    }
                    p {
                      text-align: center;
                      margin: 1.5rem 0 0;
                      padding: 0;
                      font-size: 1rem;
                    }
                  }
                }
                .keywords {
                  top: 78vh;
                  & > div {
                    width: 6rem;
                    height: 6rem;
                    font-size: 0.8rem;
                    margin: 0 0.25rem;
                  }
                }
              }
            }
          }
          &.section03 {
            .content {
              padding: 5rem 0 6rem;
              background: url("../images/service/upwallet/bg_upwallet_m.png") no-repeat center / cover;
              .container {
                .structure {
                  margin-top: 5rem;
                  &:nth-of-type(1) {
                    width: 80%;
                    height: auto;
                  }
                  &:nth-of-type(2) {
                    width: 100%;
                    height: auto;
                  }
                  img {
                    width: 100%;
                    height: auto;
                  }
                }
              }
            }
          }
          &.section04 {
            padding: 5rem 0;
            img {
              margin: 6vh 0 0;
            }
          }
        }
      }
    }
    // -- UP T3
    #svc-t3 {
      .panel {
        &.visual {
          .container {
            .typo {
              text-align: center;
            }
          }
        }
        &.bottom {
          section {
            padding: 5rem 0;
            &.section01,
            &.section03 {
              padding: 0;
              .container {
                .change-panel-wrap {
                  .change-panel-list {
                    margin: 0;
                    padding: 0 !important;
                    height: 75vh;
                    article {
                      flex-direction: column;
                    }
                  }
                }
              }
            }
            &.section01 {
              .title-bold-02 {
                font-size: 1.25rem;
              }
              .container {
                .change-panel-wrap {
                  padding: 8rem 0 0 !important;
                  .change-panel-list {
                    height: 62vh;
                    article {
                      &:not(.change-01) {
                        transform: translateY(20px);
                        opacity: 0;
                      }
                      & > * {
                        flex-basis: auto;
                        flex-grow: 0;
                        h3 {
                          font-size: 1.25rem;
                        }
                        strong {
                          font-size: 2rem;
                        }
                        p {
                          padding: 0 2rem;
                          line-height: 1.6;
                        }
                        &.align-items-start {
                          order: 2;
                          align-items: center !important;
                          text-align: center;
                        }
                        &.img-box {
                          order: 0;
                          flex-basis: 40vw;
                          width: 40vw;
                          height: 40vw;
                          margin: 0;
                          img {
                            transform: scale(0.75);
                          }
                        }
                        &.strong-text {
                          padding: 2rem 0 1.5rem;
                          order: 1;
                        }
                      }
                    }
                  }
                }
              }
            }
            &.section02 {
              .swiper-wrap {
                margin: 0;
                padding: 0;
                .swiper-button-prev,
                .swiper-button-next {
                  display: none;
                }
                .swiper-t3 {
                  .swiper-wrapper {
                    .swiper-slide {
                      padding: 2rem 1rem;
                      .img-box {
                        height: 140px;
                        img {
                          transform: scale(0.8);
                        }
                      }
                      h3 {
                        font-size: 1.25rem;
                      }
                      p {
                        text-align: center;
                        line-height: 1.6;
                      }
                    }
                  }
                }
              }
            }
            &.section03 {
              .container {
                padding: 0;
                .change-panel-wrap {
                  padding: 4rem 1.5rem 0 !important;
                  .change-panel-list {
                    height: 80vh;
                    .change-panel-02-box {
                      width: 88vw;
                      height: 88vw;
                      border-width: 2px;
                    }
                    article {
                      & > div {
                        h3 {
                          padding-top: 0;
                          font-size: 1.25rem;
                        }
                        strong {
                          font-size: 1rem;
                        }
                        p {
                          font-size: 0.9rem;
                          line-height: 1.6;
                        }
                      }
                      &.change-01 {
                        & > div {
                          &:after {
                            border-right-width: 2px;
                            border-bottom-width: 2px;
                          }
                        }
                      }
                      &.change-02 {
                        & > div {
                          &:after {
                            border-left-width: 2px;
                            border-top-width: 2px;
                          }
                        }
                      }
                      &.change-03 {
                        & > div {
                          &:after {
                            border-right-width: 2px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // -- UPTALK
    #svc-uptalk {
      section {
        padding: 0;
        // 비쥬얼
        &.visual {
          .container {
            padding: 0;
            .main-img {
              top: calc(100vh - 579px);
              left: 18%;
            }
          }
        }
        // 텍스트 영역
        &.text-area {
          h5 {
            font-size: 1.5rem;
            padding: 8rem 0;
          }
        }
        // 업무소통 탭, 문서관리 탭
        &.work-tab,
        &.document-tab {
          min-height: auto;
          padding: 5rem 0;
        }
        // 탭 공통
        .tab-area {
          flex-direction: column;
          justify-content: center;
          .top-area {
            p {
              margin: 0;
              font-weight: bold;
              text-align: center;
              &.sub-title {
                padding-bottom: 5px;
                font-size: 1.1rem;
                color: var(--bs-gray);
              }
              &.title {
                font-size: 1.5rem;
              }
            }
            img {
              width: auto;
              max-height: 56vh;
              margin: 0 auto;
            }
          }
          .bottom-area {
            width: 100%;
            text-align: center;
          }
          .nav-tabs {
            width: 100%;
            position: relative;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            margin: 0;
            padding-bottom: 6rem;
            border: none;
            .nav-item {
              flex: 1;
              border: none;
              margin: 0 5px;
              .nav-link {
                padding: 0;
                h5.title {
                  width: 100%;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  white-space: pre-wrap;
                  text-align: center;
                  font-size: 1.1rem;
                  height: 100px;
                  padding: 0;
                }
                p {
                  position: absolute;
                  top: 90px;
                  left: 0;
                  margin: 0;
                  width: 100%;
                  height: auto;
                  text-align: center;
                  font-size: 1rem;
                  padding: 2rem 3rem;
                }
                &.active {
                }
              }
            }
          }
        }
        // 홍보영상
        &.video-area {
          .container {
            padding: 0;
          }
        }
        // 연간 이용료
        &.yearly-fee {
          padding: 5rem 0;
          h5 {
            font-size: 1.5rem;
            padding-bottom: 2rem;
          }
          div.fee-area {
            h6 {
              font-size: 18px;
              padding: 0;
            }
            p {
              font-size: 18px;
              span {
                font-size: 22px;
              }
            }
          }
        }
      }
    }
    // -- HMPS
    #svc-hmps {
      .panel {
        &.visual {
          .container {
            .typo {
              text-align: center;
              // padding-top: 4vh;
            }
          }
        }
        &.bottom {
          section {
            &.section01,
            &.section02 {
              padding: 0;
              .change-panel-wrap {
                padding: 5rem 0 0 !important;
                .change-panel-list {
                  margin: 4rem 0 0;
                  padding: 0 !important;
                  height: 70vh;
                  article {
                    &:not(.change-01) {
                      transform: translateY(20px);
                      opacity: 0;
                    }
                    .container {
                      flex-direction: column;
                      height: 100%;
                      & > div {
                        flex: auto;
                        &.img-box {
                          order: 1;
                          width: 45vh;
                          height: 45vh;
                        }
                        &.text-box {
                          padding: 0 2rem;
                          height: auto;
                          h3 {
                            font-size: 1.2rem;
                          }
                          p {
                            font-size: 0.9rem;
                            line-height: 1.6;
                            font-weight: 400;
                          }
                        }
                      }
                    }
                  }
                  .dot-box {
                    i {
                      width: 10px;
                      height: 10px;
                      margin: 0 0.25rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // WORKS (포트폴리오)
    // --LIST
    #works-list {
      .container {
        .title-area {
          .nav-list {
            margin: 1rem 0 0;
            flex-wrap: wrap;
            .form-check {
              label {
                padding: 0.4rem 1rem;
                margin-bottom: 0.5rem;
                font-size: 1rem;
              }
            }
          }
        }
        ul.list-group {
          --bs-gutter-x: 1rem;
          --bs-gutter-y: 2rem;
          li.works-item {
            flex: 50% 0 0;
            padding: 5px;
            .inner {
              border-radius: 16px;
              .row {
                padding: 0.5rem 0.5rem 1rem;
                .tab-badge {
                  span {
                    font-size: 0.8rem;
                    padding: 2px 5px;
                    margin: 0 2px 2px 0;
                  }
                }
                h5 {
                  font-size: 1.2rem;
                  height: 3.6rem;
                  line-height: 1.8rem;
                }
                small {
                  font-size: 0.85rem;
                }
              }
            }
          }
        }
      }
    }
    // --DETAIL
    #works-detail {
      .mockup-box {
        width: 70vw;
        border-radius: 10px;
        box-shadow: 10px 10px 30px rgba(24, 48, 63, 0.35);
        &.mobile {
          width: 47%;
        }
        &.mobileLandscape {
          width: 70vw;
        }
        &.tablet {
          width: 60vw;
        }
        &.tabletLandscape {
          width: 70vw;
        }
      }
      & > .mockup-box {
        bottom: inherit;
        top: 50vh;
        right: 5vw;
        width: 90vw;
        &.mobile {
          width: 50vw;
          right: 25vw;
          top: 40vh;
          & ~ .panel {
            &.visual {
              .typo {
                margin-top: -10vh;
              }
            }
            &.bottom {
              section {
                &.overview {
                  padding-top: 30vh;
                }
              }
            }
          }
        }
        &.tablet {
          width: 80vw;
          right: 10vw;
          & ~ .panel {
            &.bottom {
              section {
                &.overview {
                  padding-top: 35vh;
                }
              }
            }
          }
        }
      }
      .panel {
        &.visual {
          section {
            justify-content: flex-start;
            padding-top: 30vh;
            .typo {
              text-align: center;
              max-width: 100%;
              padding: 0 1rem;
              .title-bold {
                font-size: 2.2rem;
                line-height: 1.4;
              }
              .sub-title-white {
                margin-top: 0.5rem;
                font-weight: 400;
                font-size: 1.25rem;
              }
            }
          }
          .coverImg {
            filter: blur(4px);
          }
        }
        &.bottom {
          section {
            padding: 5rem 0;
            .content {
              margin-top: 8vh;
            }
            &.overview {
              .content {
                flex-direction: column;
                & > h2 {
                  flex: auto 0 0;
                  padding-right: 0;
                  margin-bottom: 1rem;
                }
                & > div {
                  flex: auto 0 0;
                  h5 {
                    font-weight: 400;
                    color: var(--bs-gray);
                  }
                }
              }
            }
            &.pin-area {
              .small-device-text {
                display: block;
                font-size: 1.2rem;
                line-height: 1.8;
                font-weight: 400;
                color: var(--bs-gray);
                max-width: 70%;
                word-break: keep-all;
                margin: 1.5rem 0;
                strong {
                  color: var(--bs-primary);
                  font-weight: 400;
                }
              }
              .content {
                margin-top: 0;
                flex-direction: column;
                justify-content: flex-start;
                .pin-box {
                  max-height: inherit;
                  .pin-spacer {
                    display: none !important;
                  }
                  .mockup-wrap {
                    width: 100%;
                    padding: 0;
                    margin-top: 3rem;
                    & > * {
                      margin-bottom: 6%;
                    }
                    &.mobile {
                      margin-top: 0;
                      padding-top: 50%;
                      & > * {
                        &:nth-of-type(odd) {
                          margin-top: -50%;
                          margin-left: 6%;
                        }
                        &:nth-of-type(even) {
                          align-self: inherit;
                        }
                      }
                    }
                    &.tablet {
                      flex-direction: column;
                      padding-top: 0;
                      & > * {
                        margin-bottom: 6%;
                        &:nth-of-type(odd) {
                          margin: 0;
                          margin-bottom: 6%;
                        }
                        &:nth-of-type(even) {
                          align-self: flex-end;
                        }
                      }
                    }
                  }
                }
              }
            }
            &.next-work {
              padding: 2rem 0;
              .container {
                .btn-list {
                  font-size: 0.85rem;
                  padding: 0 1rem;
                  height: 40px;
                  line-height: 40px;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  border-width: 1px;
                  & + .work-info {
                    margin-top: 1.25rem;
                  }
                }
                .work-info {
                  h3 {
                    text-align: right;
                    font-size: 1.5rem;
                  }
                  span {
                    text-align: right;
                    font-size: 1rem;
                  }
                  svg {
                    width: 2rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    // NEWS (보도자료)
    // --LIST
    #news-list {
      ul.list-group {
        flex-direction: column;
        --bs-gutter-x: 0;
        li.news-item {
          width: 100%;
          flex: auto 0 0;
          margin-right: 0;
          padding: 0 0 2rem;
          .inner {
            border-radius: 16px;
            .row {
              padding: 0.5rem 1rem 1rem;
              span {
                font-size: 0.9rem;
              }
              h5 {
                font-size: 1.3rem;
                height: auto;
              }
            }
          }
        }
      }
    }
    // --DETAIL
    #news-detail {
      margin: 0 auto;
      .news-wrap {
        position: relative;
        .news-title {
          position: inherit;
          width: 100%;
          height: auto;
          .title-inner {
            width: 100%;
            padding: 15vh 1.5rem 5vh;
            height: auto;
            h2 {
              font-size: 2rem;
              padding-right: 23vw;
              margin-bottom: 10vh;
            }
          }
        }
        .news-content {
          padding: 0;
          .content-inner {
            min-width: inherit;
            padding: 3rem 1.5rem;
          }
          .btn-area {
            padding: 2rem 0 4rem;
            button {
              &.btn-list {
                font-size: 0.9rem;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    // CONTACT (프로젝트 문의)
    #contact {
      .title-area {
        text-align: center;
        h5 {
          margin-top: 1rem;
          font-size: 1rem;
        }
        h2 {
          font-size: 1.4rem;
        }
      }
      .form-wrap {
        margin: 0;
      }
      .information {
        margin-top: 4rem;
        padding: 0;
        background: transparent;
        border-radius: 0;
        div {
          margin-top: 3rem;
          h5 {
            font-size: 1.1rem;
            margin-bottom: 0;
          }
          strong {
            font-size: 2.4rem;
            line-height: 1.6;
            font-weight: 700;
          }
          dl {
            &:first-of-type {
              margin-top: 1rem;
            }
            margin-top: 0.5rem;
            flex-direction: column;
            align-items: stretch;
            dt {
              flex: auto 0 0;
              font-size: 1rem;
              margin-bottom: 0.25rem;
              font-weight: 500;
            }
            dd {
              font-weight: 400;
              font-size: 1rem;
            }
          }
        }
      }
      #faq {
        margin-top: 3rem;
        padding: 2rem 0;
        h3 {
          text-align: center;
          margin-bottom: 2rem;
        }
        .accordion-flush {
          .accordion-item {
            margin-bottom: 0.5rem;
            .accordion-button {
              padding: 1.25rem 1.5rem;
              font-size: 1rem;
              &::after {
                background-size: 18px auto;
              }
            }
            .accordion-body {
              font-size: 0.95rem;
              padding: 0 1.5rem 1.5rem;
            }
          }
        }
      }
    }

    // 404 에러 페이지
    #not-found {
      .row {
        flex-direction: column-reverse;
        text-align: center;
        h1 {
          margin-top: 3rem;
          font-size: 1.5rem;
        }
        h3 {
          margin-top: 1rem;
          font-size: 1rem;
        }
        img {
          width: 50vw;
        }
        .btn-primary {
          font-size: 1rem;
        }
      }
    }

    // ///////////////////////////////////////////////////////////////////////
    // ///////////////////////////////////////////////////////////////////////
    // ///////////////////////////////////////////////////////////////////////
    // 1024보다 작지만 PC 특수경우
  }
}
