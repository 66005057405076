// page.scss
main {
  section {
    width: 100%;
    min-height: 100vh;
    display: flex;
    padding: 15vh 0 0;
    position: relative;
    &.foot {
      flex-direction: column;
      min-height: auto;
      align-items: center;
      padding: 150px 0 !important;
      background-color: var(--bs-bg-blue);
      h5 {
        font-size: 2.2rem;
        font-weight: bold;
      }
      h6 {
        font-size: 1.25rem;
        font-weight: 400;
        padding: 1rem 0 4rem;
      }
      .btn {
        font-size: 1.4rem;
        font-weight: bold;
        padding: 12px 30px;
        min-width: 180px;
        border-radius: 30px;
      }
    }
  }
  .parallax-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
  }
  .panel {
    background-color: var(--bs-white);
    position: relative;
    section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
    }
    &.visual {
      height: 100vh;
      background-color: var(--bs-primary-dark);
      color: var(--bs-white);
      section {
        height: 100vh;
      }
    }
  }
  &.noVisual {
    padding: 180px 0 0;
    .container {
      .title-area {
        margin: 50px 0;
        .title-bold-03 {
          font-size: 4rem;
        }
      }
    }
  }
}

// HOME (메인)
@include keyframe(visual-ani) {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(16px);
  }
}
#home {
  // padding: 0;
  position: relative;
  .home-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: 1;
    svg {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100vw;
      min-height: 100vh;
    }
  }
  section {
    position: relative;
    background-color: var(--bs-white);
    & > * {
      position: relative;
      z-index: 10;
    }
    .container {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
    &#visual {
      justify-content: flex-start;
      align-items: end;
      color: var(--bs-white);
      background-color: transparent;
      padding: 15vh 0;
      background-color: var(--bs-primary);
      .typo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        & > * {
          display: inline-flex;
        }
        h1 {
          margin: 0;
          color: var(--bs-white);
          font-weight: 700;
          font-size: 4rem;
        }
      }
      .effectBox {
        position: relative;
        margin-left: 1.5rem;
        display: flex;
        align-items: center;
        .react-typewriter-text-wrap {
          position: relative;
          z-index: 1;
        }
      }
      .visualimg {
        position: absolute;
        bottom: 0;
        right: 0;
        @include animation(visual-ani, 1s, 0, infinite, alternate, cubic-bezier(0.645, 0.045, 0.355, 1));
      }
    }
    .mainTxt {
      .badge {
        background-color: transparent;
        padding: 0;
        color: var(--bs-primary);
        font-size: 1.5rem;
        font-weight: 700;
        letter-spacing: 0.4rem;
        margin-bottom: 2rem;
      }
      h2 {
        font-size: 2.8rem;
        font-weight: 300;
        margin: 0;
      }
    }
    .works-box {
      display: flex;
      justify-content: flex-end;
      margin-top: -10vh;
      .works {
        position: relative;
        width: 750px;
        padding-bottom: 70vh;
        cursor: pointer;
        .ourWorks {
          position: absolute;
          top: 4vh;
          right: 340px;
          z-index: 10;
        }
        .card {
          position: absolute;
          top: 0;
          right: 200px;
          width: 345px;
          height: 450px;
          background-color: rgba(173, 196, 255, 1);
          transform: rotate(29.7deg) skewX(-30deg);
          border: 0;
          opacity: 0;
          &:nth-of-type(6) {
            background-image: url("../images/home/bg_works.jpg");
            background-size: cover;
            &:after {
              content: "";
              background: rgba(1, 37, 131, 0.6);
              width: 100%;
              height: 100%;
            }
          }
          &:nth-of-type(5) {
            background-color: rgba(173, 196, 255, 0.9);
          }
          &:nth-of-type(4) {
            background-color: rgba(173, 196, 255, 0.75);
          }
          &:nth-of-type(3) {
            background-color: rgba(173, 196, 255, 0.6);
          }
          &:nth-of-type(2) {
            background-color: rgba(173, 196, 255, 0.45);
          }
          &:nth-of-type(1) {
            background-color: rgba(173, 196, 255, 0.3);
          }
        }
      }
    }
    .puzzle {
      position: relative;
      padding-bottom: 55vh;
      margin-top: -8vh;
      div {
        min-width: 700px;
        width: 40vw;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &.chart-section {
      display: flex;
      flex-direction: column;
      padding-top: 2rem;
      .chart {
        margin-top: -30vh;
        position: relative;
        padding-bottom: 10vh;
        svg {
          position: relative;
          right: -300px;
          &.chartBg {
            width: 1920px;
            position: absolute;
            right: -1000px;
            bottom: -220px;
          }
          &.chartSvg {
            width: 860px;
          }
        }
      }
    }
  }
}

// ABOUT (회사소개)
#about {
  .panel {
    background-color: var(--bs-white);
    position: relative;
    section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      // .container {
      //   padding: 0;
      // }
    }
    &.visual {
      background: linear-gradient(251.18deg, #326bff -5.58%, #000c2b 89.21%);
      mix-blend-mode: multiply;
      .bg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url("../images/about/bg_visual.png") no-repeat top center;
        mix-blend-mode: multiply;
      }
      .bg2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        svg {
          width: 100%;
        }
      }
      section {
        justify-content: center;
        .typo {
          .sub-title {
            color: var(--bs-primary-light);
          }
          h1 {
            font-weight: bold;
            font-size: 3.8rem;
            line-height: 5.4rem;
            color: var(--bs-white);
          }
        }
      }
    }
    &.bottom {
      .bg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url("../images/about/bg_about.png") no-repeat top center;
        // mix-blend-mode: multiply;
      }
      section {
        min-height: auto;
        &.business {
          .container {
            flex: 1;
            display: flex;
            flex-direction: column;
          }
          .businessBox {
            margin: 8rem 0 10rem;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            img {
              max-width: 100%;
            }
          }
          // .list {
          //   position: relative;
          //   flex: 1;
          //   display: inline-flex;
          //   justify-content: center;
          //   align-items: center;
          //   margin-bottom: 6rem;
          //   ul {
          //     position: absolute;
          //     top: 0;
          //     left: 0;
          //     width: 100%;
          //     height: 100%;
          //     display: flex;
          //     align-items: center;
          //     justify-content: center;
          //     padding: 0 20px;
          //     li {
          //       flex: 1;
          //       display: inline-flex;
          //       justify-content: center;
          //       align-items: center;
          //       font-size: 1.125rem;
          //       font-weight: 500;
          //       text-align: center;
          //     }
          //   }
          // }
        }
        &.partner {
          justify-content: center;
          background-color: var(--bs-light);
          .partners {
            margin: 8rem 0 10rem;
            mix-blend-mode: darken;
          }
        }
        &.history {
          .list {
            margin-top: 6rem;
            .pin-box {
              .pin-spacer {
                padding: 0 !important;
                .pin-content {
                  &.noAlpha {
                    .txtLine {
                      h3 {
                        span {
                          position: absolute;
                          left: 128px;
                          top: 0;
                          transform: translateY(-100px);
                        }
                      }
                    }
                  }
                }
              }
            }
            h3 {
              font-weight: 700;
              font-size: 7rem;
              line-height: 5.5rem;
              color: var(--bs-primary);
            }
            ul {
              padding: 5rem 0;
              border-top: 1px solid var(--bs-border);
              li {
                font-size: 1.2rem;
                line-height: 1.6;
                font-weight: 400;
                margin-bottom: 1rem;
              }
            }
          }
        }
        &.certifications {
          margin-top: 4rem;
          padding: 15vh 0;
          background: var(--bs-primary-dark);
          ul {
            margin-top: 3rem;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            li {
              flex-basis: calc(25% - 30px);
              flex-grow: 0;
              flex-shrink: 0;
              margin-right: 40px;
              margin-bottom: 40px;
              cursor: pointer;
              &:nth-child(4n) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

// SERVICE (서비스)
.service {
  .panel {
    &.visual {
      .cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, rgba(255, 255, 255, 1) 95%);
      }
      .container {
        width: 100%;
        height: 100%;
        .typo {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          padding-bottom: 20vh;
          text-align: center;
          .title-bold-03 {
            line-height: 1.2;
            letter-spacing: 0.1rem;
            &.fullname {
              font-size: 2.5rem;
            }
          }
          .title-bold-02 {
            font-size: 2rem;
            margin-top: 1.5rem;
          }
          p.sub-title-white {
            margin: 1.5rem 0 0;
            font-size: 1.6rem;
            line-height: 1.6;
            letter-spacing: 0;
          }
        }
      }
    }
  }
  .bottom {
    section {
      .typo {
        p {
          margin: 1rem 0 0;
          font-size: 1.4rem;
          line-height: 1.8;
          font-weight: 500;
        }
      }
      &.section01 {
        .container {
          overflow-x: visible;
          ul.words {
            padding: 5rem 0 15vh;
            margin-left: -20px;
            width: 180%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            // align-items: center;
            li {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: nowrap;
              span {
                flex: auto 0 0;
                margin-right: 2.5rem;
                margin-top: 0.5rem;
                padding: 0 20px;
                font-size: 4.6rem;
                line-height: 1.8;
                font-weight: 400;
                border-radius: 100px;
                color: var(--bs-gray-400);
                position: relative;
                white-space: nowrap;
                transition: 0.5s ease-in-out;
                z-index: 0;
                &.highlight {
                  &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    border-radius: 100px;
                    width: 100%;
                    height: 100%;
                    background-color: var(--bs-primary);
                    z-index: -5;
                  }
                  &:after {
                    content: "";
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    display: block;
                    width: calc(100% + 2px);
                    height: calc(100% + 2px);
                    background-color: var(--bs-white);
                    transform-origin: right center;
                    transition: 0.3s ease-in-out;
                    z-index: -1;
                  }
                  &.active {
                    color: var(--bs-white);
                    &:after {
                      opacity: 1;
                      transform: scaleX(0);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// -- UP NFT
#svc-nft {
  .btn_link {
    background: rgba(255, 255, 255, 1);
    color: #072885;
  }
  section {
    background-color: var(--bs-bg-blue);
    .mockup {
      max-width: 100%;
      // border-radius: 20px;
      margin-top: 5rem;
      margin-bottom: -9.2rem;
      box-shadow: 0px 12px 29px 3px rgba(0, 0, 0, 0.1);
    }
  }
}
// -- UPTS
#svc-ts {
  .bottom {
    section {
      &.section02,
      &.section07 {
        padding: 15vh 0;
        min-height: auto;
        .text-center {
          margin: 8vh auto 0;
        }
      }
      &.section02 {
        background: var(--bs-bg-blue);
        .text-center {
          width: 370px;
          height: 262px;
          background-image: url("../images/service/upts/isms.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% auto;
        }
      }
      &.section03 {
        padding: 0;
        height: 250vh;
        display: block;
        .container {
          height: 100%;
          .change-panel-wrap-01 {
            width: 100% !important;
            position: relative;
            transform: translateY(0) !important;
            top: 0 !important;
            padding: 10vh 0 !important;
            height: 250vh !important;
            .change-panel-list {
              position: relative;
              margin-top: 2rem;
              height: 50vh;
              article {
                position: absolute;
                width: 100%;
                height: 100%;
                // background: rgba(245,54,21,0.5);
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &:not(.change-01) {
                  transform: translateY(60px);
                  opacity: 0;
                }
                img {
                  height: 40vh;
                }
                p {
                  max-width: 80%;
                  margin: 2rem 0 0;
                  font-size: 1.2rem;
                  line-height: 1.65;
                  font-weight: 500;
                  color: var(--bs-gray);
                }
              }
            }
            .keywords {
              position: absolute;
              top: 78vh;
              left: 0;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              & > div {
                width: 140px;
                height: 140px;
                border: 1px solid var(--bs-gray);
                border-radius: 100%;
                margin: 0 10px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 1.125rem;
                font-weight: 700;
                color: var(--bs-gray);
                transition: 0.3s ease-in-out;
                &.active {
                  border-color: var(--bs-primary);
                  background-color: var(--bs-primary);
                  color: var(--bs-white);
                }
              }
            }
            &.leave {
              position: absolute;
              bottom: 2vh !important;
              left: 0 !important;
              top: auto !important;
            }
          }
        }
      }
      &.section04 {
        padding: 0;
        height: 300vh;
        display: block;
        background-color: var(--bs-bg-blue);
        .container {
          height: 100%;
          .change-panel-wrap-02 {
            width: 100% !important;
            position: relative;
            padding: 10vh 0 !important;
            height: 300vh !important;
            .circle-wrap {
              width: 100%;
              height: 45vh;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              position: relative;
              .circle {
                transform: scale(1);
                display: inline-flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 10px;
                width: 280px;
                height: 280px;
                background: var(--bs-white);
                border-radius: 100%;
                box-shadow: 0px 4px 10px #d6d6d6;
                transform-origin: center;
                transition: transform 0.5s ease-out;
                z-index: 0;
                strong {
                  font-size: 1.25rem;
                  font-weight: 700;
                  color: var(--bs-gray-500);
                  padding: 1rem 0 0;
                }
                small {
                  font-size: 1.125rem;
                  font-weight: 500;
                  color: var(--bs-gray-500);
                  margin-top: 1rem;
                }
                &.active {
                  z-index: 1;
                  transform: scale(1.2);
                  box-shadow: 0px 6px 16px #93b7ff;
                  strong {
                    font-size: 1.45rem;
                  }
                  small {
                    font-size: 1.1rem;
                  }
                  strong,
                  small {
                    color: var(--bs-white);
                  }
                }
              }
              .pagination-wrap {
                position: absolute;
                top: calc(50% - 2rem);
                right: 0;
                display: flex;
                flex-direction: column;
                display: none;
                .pagination {
                  display: block;
                  width: 0.5rem;
                  height: 0.5rem;
                  border: 1px solid var(--bs-gray);
                  border-radius: 100%;
                  margin: 0.25rem 0;
                  &.active {
                    border: 0;
                  }
                }
              }
              &.state0 {
                .circle.active,
                .pagination.active {
                  background: linear-gradient(34.44deg, #0047ff -6.45%, #a993ff 121.17%);
                }
                & + .range-area {
                  .range-bar {
                    .range-active {
                      background-color: var(--bs-primary);
                    }
                  }
                }
              }
              &.state1 {
                .circle.active,
                .pagination.active {
                  background: linear-gradient(47.65deg, #04db74 10.77%, #01f2f2 92.58%);
                }
                & + .range-area {
                  .range-bar {
                    .range-active {
                      background-color: #20e3b2;
                    }
                  }
                }
              }
              &.state2 {
                .circle.active,
                .pagination.active {
                  background: linear-gradient(90deg, #ec008c 0%, #fc6767 100%);
                }
                & + .range-area {
                  .range-bar {
                    .range-active {
                      background-color: #f01485;
                    }
                  }
                }
              }
              &.state3 {
                .circle.active,
                .pagination.active {
                  background: linear-gradient(34.44deg, #ea52f8 -6.45%, #0066ff 121.17%);
                }
                & + .range-area {
                  .range-bar {
                    .range-active {
                      background-color: #a459fb;
                    }
                  }
                }
              }
            }
            .range-area {
              width: 100%;
              margin-top: 8vh;
              .range-bar {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 36px;
                background: #e1e5f4;
                box-shadow: inset -2px 3px 10px rgba(0, 0, 0, 0.25);
                border-radius: 40px;
                padding: 10px;
                .range-active {
                  width: 0%;
                  height: 100%;
                  border-radius: 40px;
                }
              }
              .range-text {
                padding: 0.75rem 0;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                text-align: center;
                li {
                  font-size: 1.25rem;
                  font-weight: 500;
                  word-break: keep-all;
                  color: var(--bs-gray);
                  transition: 0.3s ease-in-out;
                  &:nth-of-type(1) {
                    text-align: left;
                  }
                  &:nth-of-type(4) {
                    text-align: right;
                  }
                }
              }
            }
          }
        }
      }
      &.section05 {
        padding: 15vh 0;
        .imgBox {
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            display: block;
            max-width: 100%;
          }
          .btn_zoom {
            margin-top: 15px;
            width: 36px;
            height: 36px;
            background: url("../images/service/upts/ic_zoom.png") no-repeat center;
            display: none;
          }
        }
      }
      &.section06 {
        background-color: var(--bs-dark);
        padding: 15vh 0;
      }
    }
  }
}
// -- UP WALLET
#svc-wallet {
  .bottom {
    section {
      &.section02 {
        padding: 0;
        height: 250vh;
        display: block;
        background-color: var(--bs-bg-blue);
        .container {
          height: 100%;
          .change-panel-wrap {
            width: 100% !important;
            position: relative;
            transform: translateY(0) !important;
            top: 0 !important;
            padding: 10vh 0 !important;
            height: 250vh !important;
            .change-panel-list {
              position: relative;
              margin-top: 1rem;
              height: 45vh;
              article {
                position: absolute;
                width: 100%;
                height: 100%;
                // background: rgba(245,54,21,0.5);
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &:not(.change-01) {
                  transform: translateY(60px);
                  opacity: 0;
                }
                img {
                  height: 32vh;
                }
                p {
                  max-width: 80%;
                  margin: 1rem 0 0;
                  font-size: 1.2rem;
                  line-height: 1.65;
                  font-weight: 500;
                  color: var(--bs-gray);
                }
              }
            }
            .keywords {
              position: absolute;
              top: 78vh;
              left: 0;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              & > div {
                width: 140px;
                height: 140px;
                border: 1px solid var(--bs-gray);
                border-radius: 100%;
                margin: 0 10px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 1.125rem;
                font-weight: 700;
                color: var(--bs-gray);
                transition: 0.3s ease-in-out;
                &.active {
                  border-color: var(--bs-primary);
                  background-color: var(--bs-primary);
                  color: var(--bs-white);
                }
              }
            }
            &.leave {
              position: absolute;
              bottom: 2vh !important;
              left: 0 !important;
              top: auto !important;
            }
          }
        }
      }
      &.section03 {
        background-color: #001650;
        padding: 0;
        .content {
          padding: 15vh 0 20vh;
          background: url("../images/service/upwallet/bg_upwallet.png") no-repeat 10px 0 / cover;
          .container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .structure {
              margin-top: 8vh;
              position: relative;
              &:nth-of-type(1) {
                height: 70vh;
                align-self: flex-end;
              }
              &:nth-of-type(2) {
                height: 70vh;
              }
              img {
                height: 100%;
              }
              svg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      &.section04 {
        padding: 15vh 0;
        img {
          max-width: 100%;
          margin: 10vh 0 0;
        }
      }
    }
  }
}
// -- UP T3
#svc-t3 {
  .panel {
    &.visual {
      .container {
        .typo {
          text-align: left;
        }
      }
    }
    &.bottom {
      section {
        &.section01,
        &.section03 {
          padding: 0;
          height: 250vh;
          display: block;
          .container {
            height: 100%;
            .change-panel-wrap {
              width: 100% !important;
              position: relative;
              transform: translateY(0) !important;
              top: 0 !important;
              height: 250vh !important;
              .change-panel-list {
                position: relative;
                margin-top: 2rem;
                height: 65vh;
                article {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  // background: rgba(245,54,21,0.5);
                  top: 0;
                  left: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  &:not(.change-01) {
                    transform: translateY(60px);
                    opacity: 0;
                  }
                }
              }
              &.leave {
                position: absolute;
                bottom: 2vh !important;
                left: 0 !important;
                top: auto !important;
              }
            }
          }
        }
        &.section01 {
          .container {
            .change-panel-wrap {
              padding: 15vh 0 !important;
              .change-panel-list {
                article {
                  & > * {
                    flex: 1;
                    flex-shrink: 0;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    h3 {
                      font-weight: 600;
                      color: var(--bs-gray);
                      font-size: 1.5rem;
                    }
                    strong {
                      font-weight: 800;
                      font-size: 3.5rem;
                      color: var(--bs-dark);
                    }
                    p {
                      margin-top: 1rem;
                      font-size: 1rem;
                      font-weight: 500;
                      line-height: 1.8;
                      word-break: keep-all;
                    }
                    &.img-box {
                      margin: 0 3rem;
                      flex-basis: 380px;
                      flex-grow: 0;
                      width: 380px;
                      height: 380px;
                      border-radius: 100%;
                      background: linear-gradient(44.07deg, #326bff 7.07%, #a289ef 84.53%);
                    }
                  }
                }
              }
            }
          }
        }
        &.section02 {
          background: linear-gradient(44.07deg, rgba(51, 109, 255, 0.4) 7.07%, rgba(162, 137, 239, 0.4) 84.53%);
          min-height: auto;
          padding: 12vh 0;
          .swiper-wrap {
            position: relative;
            padding: 0 4rem;
            margin: 0 -4rem;
            .swiper-button-prev,
            .swiper-button-next {
              width: 48px;
              height: 48px;
              background-color: var(--bs-white);
              border-radius: 100%;
              box-shadow: 0px 0px 1px 1px rgba(152, 152, 152, 0.5);
              background-repeat: no-repeat;
              background-size: 10px auto;
              &:after {
                display: none;
              }
            }
            .swiper-button-prev {
              left: 0;
              background-image: url("../images/ic_arrow_prev.png");
              background-position: 18px center;
            }
            .swiper-button-next {
              right: 0;
              background-image: url("../images/ic_arrow_next.png");
              background-position: 20px center;
            }
            .swiper-t3 {
              margin: 2rem 0 0;
              .swiper-wrapper {
                align-items: stretch;
                .swiper-slide {
                  min-width: 1px;
                  height: auto;
                  flex-grow: 1;
                  align-self: stretch;
                  background: var(--bs-white);
                  border: 1px solid #bdbdbd;
                  border-radius: 8px;
                  padding: 80px 2rem;
                  .img-box {
                    display: flex;
                    width: 100%;
                    height: 180px;
                    justify-content: center;
                    align-items: center;
                    img {
                      max-width: 100%;
                    }
                  }
                  h3 {
                    margin-top: 1.5rem;
                    text-align: center;
                    font-size: 1.25rem;
                    font-weight: 600;
                  }
                  p {
                    margin-top: 1rem;
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1.8;
                    word-break: keep-all;
                  }
                }
              }
            }
          }
        }
        &.section03 {
          .container {
            .change-panel-wrap {
              padding: 10vh 0 !important;
              .change-panel-list {
                margin-top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                .change-panel-02-box {
                  width: 600px;
                  height: 600px;
                  // width: 68vh;
                  // height: 68vh;
                  border-width: 3px;
                  border-style: dashed;
                  border-color: #8b138e;
                  border-radius: 100%;
                  position: relative;
                  z-index: 1;
                  & > div {
                    position: absolute;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    & > figure {
                      width: 150px;
                      height: 150px;
                      background-repeat: no-repeat;
                      background-position: center;
                      background-size: contain;
                      transform: scale(0.6);
                      transition: 0.2s ease-in-out;
                      margin: 0;
                    }
                    &.box-01 {
                      top: 1rem;
                      left: 0;
                    }
                    &.box-02 {
                      bottom: 1rem;
                      left: 0;
                    }
                    &.box-03 {
                      width: auto;
                      flex-direction: column;
                      top: -75px;
                      left: calc(50% - 75px);
                      height: calc(100% + 150px);
                    }
                    &.active {
                      & > figure {
                        transform: scale(1);
                      }
                    }
                  }
                }
                article {
                  &:not(.change-01) {
                    transform: translateY(0);
                  }
                  & > div {
                    width: 380px;
                    height: 380px;
                    border-radius: 100%;
                    background: var(--bs-white);
                    text-align: center;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    padding: 2rem;
                    &:before {
                      content: "";
                      display: block;
                      width: calc(100% + 6px);
                      height: calc(100% + 6px);
                      border-radius: 100%;
                      background: linear-gradient(44.07deg, #326bff 7.07%, #a289ef 84.53%);
                      position: absolute;
                      top: -3px;
                      left: -3px;
                      z-index: -1;
                    }
                    &:after {
                      content: "";
                      display: block;
                      width: 100%;
                      height: 100%;
                      border-color: #9385f1;
                      border-style: dashed;
                      border-width: 0;
                      position: absolute;
                      left: 0;
                      z-index: -1;
                    }
                    h3 {
                      padding-top: 1.5rem;
                      font-weight: 600;
                      color: var(--bs-dark);
                      font-size: 1.5rem;
                    }
                    strong {
                      margin-top: 0.5rem;
                      display: block;
                      font-weight: 500;
                      color: var(--bs-gray);
                      font-size: 1.1rem;
                    }
                    p {
                      margin: 1rem 0 0;
                      font-size: 1rem;
                      font-weight: 500;
                      line-height: 1.8;
                      word-break: keep-all;
                    }
                  }
                  &.change-01 {
                    & > div {
                      &:after {
                        border-right-width: 3px;
                        border-bottom-width: 3px;
                        top: -220px;
                        transform: scale(0.9) rotate(45deg);
                      }
                    }
                  }
                  &.change-02 {
                    & > div {
                      &:after {
                        border-left-width: 3px;
                        border-top-width: 3px;
                        bottom: -220px;
                        transform: scale(0.9) rotate(45deg);
                      }
                    }
                  }
                  &.change-03 {
                    & > div {
                      &:after {
                        width: 0;
                        height: 200%;
                        border-right-width: 3px;
                        left: calc(50% - 1px);
                        top: -50%;
                        transform: scale(0.9);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.section04 {
          padding: 12vh 0;
          min-height: auto;
          background-color: var(--bs-bg-blue);
        }
      }
    }
  }
}
// -- UPTALK
#svc-uptalk {
  section {
    padding: 15vh 0 0;
    min-height: auto;
    // 비쥬얼
    &.visual {
      min-height: 100vh;
      padding: 0;
      background-color: #edf1ff;
      .typo {
        justify-content: flex-start;
        position: relative;
        z-index: 1;
        padding-top: 18vh;
        color: var(--bs-dark);
        p {
          color: var(--bs-dark);
        }
      }
      .main-img {
        position: absolute;
        top: calc(100vh - 634px);
      }
    }
    // 텍스트 영역
    &.text-area {
      padding-top: 750px;
      h5 {
        text-align: center;
        font-weight: bold;
        line-height: 1.6;
        font-size: 2rem;
      }
    }
    // 업무소통, 문서관리 탭
    &.work-tab,
    &.document-tab {
      min-height: 100vh;
      padding: 0;
    }
    // 탭 공통
    .tab-area {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-area {
        padding-left: 144px;
        p {
          margin: 0;
          font-weight: bold;
          &.sub-title {
            padding-bottom: 5px;
            font-size: 1.375rem;
            color: #797979;
          }
          &.title {
            font-size: 2rem;
            color: var(--bs-dark);
          }
        }
      }
      .right-area {
        img {
          width: 85%;
          transform: translateX(47px);
        }
      }
      .nav-tabs {
        display: flex;
        flex-direction: column;
        border: none;
        width: 394px;
        margin-top: 3rem;
        .nav-item {
          border: none;
          .nav-link {
            background-color: transparent;
            padding: 0 0 1rem 0;
            border: none;
            h5.title {
              font-weight: bold;
              font-size: 1.5rem;
              color: var(--bs-gray);
              background-color: rgba(255, 255, 255, 0.5);
              border: 2px solid var(--bs-primary);
              border-radius: 10px;
              padding: 1.5rem 2rem;
            }
            p {
              font-weight: 500;
              font-size: 1.12rem;
              color: var(--bs-dark);
              padding: 1.5rem;
              word-break: keep-all;
              height: 130px;
            }
            &.active {
              h5.title {
                color: var(--bs-dark);
                background-color: rgba(255, 255, 255, 1);
                box-shadow: 2px 6px 4px 0px rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
      }
    }
    // 문서관리 탭
    &.document-tab {
      background-color: #edf1ff;
    }
    // 홍보영상
    &.video-area {
    }
    // 연간 이용료
    &.yearly-fee {
      padding: 25vh 0 15vh;
      text-align: center;
      h5 {
        font-size: 40px;
        font-weight: bold;
        padding-bottom: 80px;
      }
      div.fee-area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #bdbdbd;
        border-radius: 10px;
        font-weight: bold;
        padding: 20px 30px;
        h6 {
          font-size: 1.5rem;
          color: var(--bs-gray);
          padding-bottom: 0;
        }
        p {
          display: flex;
          align-items: baseline;
          font-size: 1.5rem;
          color: var(--bs-dark);
          margin: 0;
          span {
            font-size: 2.2rem;
            color: var(--bs-primary);
          }
        }
      }
    }
  }
}
// -- HMPS
#svc-hmps {
  .btn_link {
    background: rgba(255, 255, 255, 1);
    color: #d31b00;
  }
  .panel {
    &.visual {
      color: var(--bs-dark);
      .container {
        .typo {
          text-align: left;
          justify-content: flex-start;
          padding-bottom: 0;
          padding-top: 10vh;
          .title-bold-03 {
            letter-spacing: 0;
          }
        }
      }
    }
    &.bottom {
      section {
        &.section01,
        &.section02 {
          padding: 0;
          display: block;
          .change-panel-wrap {
            width: 100% !important;
            position: relative;
            transform: translateY(0) !important;
            top: 0 !important;
            padding: 10vh 0 !important;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            color: var(--bs-white);
            .change-panel-list {
              position: relative;
              margin-top: 2rem;
              height: 65vh;
              article {
                position: absolute;
                width: 100%;
                height: 100%;
                // background: rgba(245,54,21,0.5);
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                &:not(.change-01) {
                  transform: translateY(60px);
                  opacity: 0;
                }
                .container {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  & > div {
                    flex: 1;
                    &.img-box {
                      img {
                        max-width: 100%;
                      }
                    }
                    &.text-box {
                      padding: 0 7rem;
                      h3 {
                        font-size: 1.75rem;
                        font-weight: 600;
                        color: #ff8e7d;
                      }
                      p {
                        margin: 1rem 0 0;
                        font-size: 1.2rem;
                        font-weight: 500;
                        line-height: 1.8;
                        word-break: keep-all;
                      }
                    }
                  }
                }
              }
              .dot-box {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                i {
                  width: 12px;
                  height: 12px;
                  border-radius: 100%;
                  background-color: var(--bs-white);
                  opacity: 0.3;
                  margin: 0 0.3rem;
                  transition: 0.2s ease-in-out;
                  &.active {
                    opacity: 1;
                  }
                }
              }
            }
            &.leave {
              position: absolute;
              bottom: 2vh !important;
              left: 0 !important;
              top: auto !important;
            }
          }
        }
        &.section01 {
          height: 400vh;
          .change-panel-wrap {
            height: 400vh !important;
            background-image: url("../images/service/hmps/bg_mice.jpg");
          }
        }
        &.section02 {
          height: 200vh;
          .change-panel-wrap {
            height: 200vh !important;
            background-image: url("../images/service/hmps/bg_admin.jpg");
            .change-panel-list {
              article {
                .container {
                  & > div {
                    &.text-box {
                      h3 {
                        color: #ba66ff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.foot {
          background-color: #08122b;
          color: var(--bs-white);
        }
      }
    }
  }
}

// WORKS (포트폴리오)
// --LIST
#works-list {
  .container {
    .title-area {
      .nav-list {
        display: flex;
        margin-top: 2rem;
        .form-check {
          min-height: auto;
          padding-left: 0;
          margin-bottom: 0;
          label {
            margin-right: 0.5rem;
            border: 1px solid var(--bs-gray);
            padding: 0.6rem 1.2rem;
            background-color: transparent;
            color: var(--bs-gray);
            font-weight: 400;
            font-size: 1.1rem;
            border-radius: 100px;
            cursor: pointer;
            transition: 0.2s ease-in-out;
          }
          input {
            width: 0px;
            height: 0px;
            opacity: 0;
            visibility: hidden;
            &:checked {
              & + label {
                background-color: var(--bs-primary);
                border-color: var(--bs-primary);
                color: var(--bs-white);
              }
            }
          }
        }
      }
    }
    ul.list-group {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      padding-bottom: 5rem;
      --bs-gutter-x: 40px;
      li.works-item {
        flex: 33.33% 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        opacity: 0;
        border: 0;
        top: 20px;
        overflow: hidden;
        &.hide {
          display: none;
        }
        .inner {
          width: 100%;
          height: 100%;
          background: var(--bs-white);
          border: 1px solid var(--bs-gray-300);
          position: relative;
          border-radius: 1rem;
          overflow: hidden;
          cursor: pointer;
          .inner-border {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            border-width: 0px;
            border-style: solid;
            border-color: transparent;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 1rem;
            transition: 0.2s ease-in-out;
            opacity: 0;
            visibility: hidden;
          }
        }
        figure {
          border-bottom: 1px solid var(--bs-gray-300);
          width: 100%;
          height: 0;
          padding-bottom: 100%;
          flex-shrink: 0;
          margin: 0;
          background-size: auto 100%;
          background-position: center;
          transition: 0.3s ease-in-out;
        }
        .row {
          --bs-gutter-x: 0;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          padding: 1.5rem;
          margin: 0;
          .tab-badge {
            span {
              width: auto;
              display: inline-flex;
              padding: 4px 8px;
              margin: 0 0.4rem 0.4rem 0;
              border-radius: 4px;
              font-weight: 500;
              font-size: 1rem;
              line-height: 1.5;
              background: #f3e2ff;
              color: #8a54e1;
              &.bc {
                background: var(--bs-thin-blue);
                color: #1c3a8a;
              }
              &.ai {
                background: #f8e3ff;
                color: #c53ff1;
              }
              &.ec {
                background: #e6e3ff;
                color: #402cb7;
              }
              &.ict {
                background: #c6eeff;
                color: #004b75;
              }
            }
          }
          h5 {
            margin-top: 0.4rem;
            color: var(--bs-dark);
            font-weight: 600;
            height: 4.8rem;
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2.25rem;
            margin-bottom: 0.5rem;
            word-break: keep-all;
            padding: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          small {
            font-size: 1rem;
            font-weight: 400;
            padding: 0;
          }
        }
      }
    }
  }
}
// --DETAIL
#works-detail {
  .mockup-box {
    width: 600px;
    flex-shrink: 0;
    overflow: hidden;
    background: transparent;
    border-radius: 20px;
    box-shadow: 40px 40px 100px rgba(24, 48, 63, 0.5);
    figure {
      width: 100%;
      // height: 400px;
      padding-bottom: 66.66%;
      margin: 0;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.mobile {
      width: 350px;
      border-radius: 40px;
      figure {
        padding-bottom: 220%;
        // height: 770px;
      }
    }
    &.mobileLandscape {
      width: 600px;
      figure {
        padding-bottom: 56%;
      }
    }
    &.tablet {
      width: 390px;
      figure {
        padding-bottom: 133%;
        // height: 520px;
      }
    }
    &.tabletLandscape {
      width: 600px;
      figure {
        padding-bottom: 75%;
        // height: 450px;
      }
    }
  }
  & > .mockup-box {
    width: 840px;
    position: absolute;
    top: calc(85vh - 554px);
    right: calc(50% - 760px);
    box-shadow: 20px 20px 60px rgba(24, 48, 63, 0.5);
    z-index: 10;
    &.mobile {
      width: 400px;
      top: calc(85vh - 600px);
      right: calc(50% - 520px);
    }
    &.tablet {
      width: 600px;
      right: calc(50% - 520px);
    }
  }
  .panel {
    &.visual {
      section {
        z-index: 1;
        justify-content: flex-end;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        .typo {
          max-width: 500px;
          padding-bottom: 15vh;
          text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          word-break: keep-all;
        }
      }
      .coverImg,
      .cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        z-index: -1;
      }
      .coverImg {
        background-size: cover;
        background-repeat: no-repeat;
        background-color: var(--bs-primary-dark);
        background-position: center;
      }
    }
    &.bottom {
      section {
        overflow: hidden;
        min-height: auto;
        padding: 15vh 0;
        .content {
          margin-top: 12vh;
        }
        &.overview {
          .content {
            display: flex;
            & > h2 {
              flex: 40% 0 0;
              padding-right: 210px;
              word-break: keep-all;
            }
            & > div {
              flex: 1;
              h5 {
                font-size: 1.125rem;
                line-height: 2rem;
                margin-bottom: 3rem;
              }
            }
          }
        }
        &.pin-area {
          .small-device-text {
            display: none;
          }
          .content {
            display: flex;
            justify-content: space-between;
            .pin-box {
              align-items: flex-start;
              // max-height: 2750px;
              .pin-spacer {
                .pin-content {
                  width: 384px !important;
                  max-width: 384px !important;
                  .typo {
                    h2 {
                      display: block;
                      font-size: 2.5rem;
                      font-weight: 700;
                      margin-bottom: 1rem;
                      word-break: keep-all;
                    }
                    span {
                      display: block;
                      color: var(--bs-gray);
                      font-size: 1.2rem;
                      font-weight: 400;
                      line-height: 1.8;
                      word-break: keep-all;
                    }
                  }
                }
              }
              // 기본 좌텍스트 우스크롤
              .mockup-wrap {
                display: inline-flex;
                flex-direction: column;
                align-items: flex-start;
                flex-wrap: wrap;
                padding-left: 5rem;
                &.mobile,
                &.tablet {
                  flex-direction: row-reverse;
                  padding-left: 0;
                  padding-top: 30vh;
                  & > * {
                    &:nth-of-type(odd) {
                      margin-top: -30vh;
                      margin-left: 2.5rem;
                    }
                    &:nth-of-type(even) {
                      align-self: inherit;
                    }
                  }
                }
                &.mobileLandscape {
                  padding-left: 0;
                  padding-right: 5rem;
                }
                & > * {
                  margin-bottom: 2.5rem;
                  background-position: center;
                  background-size: cover;
                  background-repeat: no-repeat;
                  &:nth-of-type(even) {
                    align-self: flex-end;
                  }
                }
              }
              // 리버스 좌스크롤 우텍스트
              &.reverse {
                flex-direction: row-reverse;
                .mockup-wrap {
                  &.mobile,
                  &.tablet {
                    flex-direction: row;
                    & > * {
                      &:nth-of-type(odd) {
                        margin-right: 2.5rem;
                        margin-left: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.next-work {
          background: var(--bs-dark);
          padding: 80px 0;
          color: #fff;
          .container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            .btn-list {
              background: transparent;
              color: var(--bs-white);
              font-size: 1.25rem;
              line-height: 1.5;
              font-weight: 400;
              opacity: 0.5;
              border: 2px solid var(--bs-white);
              border-radius: 30px;
              padding: 0.5rem 1.5rem;
              transition: 0.2s ease-in-out;
              & + .work-info {
                margin-top: 2rem;
              }
            }
            .work-info {
              cursor: pointer;
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-end;
              h3 {
                font-size: 2.8rem;
                font-weight: 700;
              }
              div {
                display: block;
                text-align: right;
                margin-top: 0.4rem;
                span {
                  font-size: 1.5rem;
                  color: var(--bs-gray);
                  display: inline-flex;
                  margin-left: 1rem;
                  &:first-of-type {
                    &:before {
                      display: none;
                    }
                  }
                  &:before {
                    content: "& ";
                    display: block;
                    margin-right: 1rem;
                  }
                }
              }
              button {
                background: transparent;
                padding: 0;
                margin-top: 1.25rem;
                opacity: 0.5;
                transition: 0.2s ease-in-out;
                svg {
                  width: 54px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// NEWS (보도자료)
// --LIST
#news-list {
  ul.list-group {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    padding-bottom: 5rem;
    --bs-gutter-x: 40px;
    li.news-item {
      flex: 33.33% 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      opacity: 0;
      border: 0;
      top: 20px;

      .inner {
        cursor: pointer;
        width: 100%;
        height: 100%;
        background: var(--bs-white);
        border: 1px solid var(--bs-gray-300);
        border-radius: 0;
        overflow: hidden;
        position: relative;
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          border: 0px solid var(--bs-primary);
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 0;
          transition: 0.2s ease-in-out;
          opacity: 0;
          visibility: hidden;
        }
        figure {
          border-bottom: 1px solid var(--bs-gray-300);
          width: 100%;
          height: 0;
          padding-bottom: 100%;
          flex-shrink: 0;
          margin: 0;
          background-size: auto 100%;
          background-position: center;
          transition: 0.3s ease-in-out;
        }
        .row {
          --bs-gutter-x: 0;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          padding: 1.5rem;
          margin: 0;
          span {
            width: auto;
            display: inline-flex;
            padding: 4px 8px;
            border-radius: 4px;
            margin-bottom: 0.6rem;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5;
            background: #f3e2ff;
            color: #8a54e1;
            &.news {
              background: var(--bs-thin-blue);
              color: var(--bs-primary);
            }
          }
          h5 {
            height: 4.8rem;
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2.25rem;
            margin-bottom: 0.5rem;
            word-break: keep-all;
            padding: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          // div {
          //   display: -webkit-box;
          //   -webkit-line-clamp: 4;
          //   max-height: 6rem;
          //   -webkit-box-orient: vertical;
          //   overflow: hidden;
          //   text-overflow: ellipsis;
          //   color: var(--bs-gray);
          //   margin-bottom: 0.5rem;
          // }
          small {
            font-size: 1rem;
            font-weight: 400;
            padding: 0;
          }
        }
      }
    }
  }
  .spinner-inner {
    padding-top: 15vh;
  }
}
// --DETAIL
#news-detail {
  margin: 0 auto;
  .news-wrap {
    position: relative;
    .news-title {
      position: fixed;
      top: 0;
      left: 0;
      width: calc(50% - 200px);
      height: 100%;
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      background-color: var(--bs-thin-blue);
      z-index: 1;
      &.leave {
        position: absolute;
        top: auto;
        bottom: 0;
        .title-inner {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      .title-inner {
        width: 330px;
        height: 100vh;
        padding: 200px 0 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        h2 {
          line-height: 1.6;
          font-size: 2.3rem;
          margin-bottom: 1rem;
          padding-right: 30px;
          word-break: keep-all;
          small {
            margin-top: 1rem;
            display: block;
            font-size: 1rem;
            font-weight: 500;
          }
        }
        .btn {
          padding: 0.65rem 2rem;
          border-radius: 100px;
          font-weight: 700;
        }
      }
    }

    .news-content {
      // background: yellow;
      padding-left: calc(50% - 200px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 110px;
      figure {
        width: 100%;
        margin: 0;
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          height: auto;
          margin: 0;
        }
      }
      .content-inner {
        padding: 100px;
        max-width: 840px;
        width: 100%;
        min-width: 600px;
      }
      h3 {
        font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }
      .post-content {
        font-size: 1.1rem;
        line-height: 1.8;
        font-weight: 500;
        color: var(--bs-gray-700);
        img {
          width: 100%;
          display: block;
          margin: 3rem 0;
        }
      }
      .post-comment {
        margin-top: 2rem;
        font-size: 1.1rem;
        line-height: 1.8;
        font-weight: 500;
        color: #999;
        // &:before{
        //   content:'"';
        //   font-family: sans-serif;
        // }
        // &:after{
        //   content:'"';
        //   font-family: sans-serif;
        // }
      }
      .btn-area {
        margin-top: 4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          background: transparent;
          &.btn-list {
            color: var(--bs-gray-900);
            padding: 0;
            font-weight: 700;
            border-bottom: 2px solid var(--bs-gray-800);
            font-size: 1rem;
            line-height: 1.6;
            transition: 0.2s ease-in-out;
          }
          &.btn-next {
            margin-left: 0.5rem;
          }
          &:disabled {
            opacity: 0.4;
          }
        }
      }
    }
  }
}

// CONTACT (프로젝트 문의)
#contact {
  .title-area {
    // text-align: center;
    h5 {
      margin-bottom: 1rem;
      font-size: 1.25rem;
      font-weight: 700;
    }
    h2 {
      font-size: 2.25rem;
      font-weight: 500;
      line-height: 1.6;
    }
  }
  .form-wrap {
    margin: 5rem 0;
  }
  .information {
    margin-top: 6rem;
    background: var(--bs-gray-100);
    border-radius: 16px;
    padding: 3.5rem;
    div {
      margin-top: 5rem;
      h5 {
        font-size: 1.75rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }
      strong {
        font-size: 3rem;
        line-height: 4rem;
        &.haveUnderline {
          position: relative;
          &:after {
            content: "";
            display: block;
            width: 100%;
            height: 3px;
            background: var(--bs-dark);
            position: absolute;
            bottom: 2px;
            left: 0;
          }
        }
      }
      dl {
        &:first-of-type {
          margin-top: 1.75rem;
        }
        margin-top: 0.75rem;
        display: flex;
        align-items: flex-start;
        dt {
          display: inline-flex;
          align-items: center;
          flex: 200px 0 0;
          font-size: 1.25rem;
          font-weight: 700;
          svg {
            margin-right: 0.25rem;
          }
        }
        dd {
          font-weight: 500;
          font-size: 1.25rem;
        }
      }
    }
  }
  #faq {
    background: #d6e1ff;
    margin-top: 6rem;
    padding: 6rem 0;
    h3 {
      margin-bottom: 3rem;
    }
    .accordion-flush {
      .accordion-item {
        margin-bottom: 1rem;
        border-radius: 10px;
        overflow: hidden;
        border: 0;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.15);
        background-color: #c1cff5;
        .accordion-button {
          padding: 2rem;
          font-size: 1.5rem;
          line-height: 1.6;
          font-weight: 700;
          color: var(--bs-dark);
          border-radius: 10px;
          background-color: #fff;
          word-break: keep-all;
          &::after {
            background-size: 24px auto;
            background-repeat: no-repeat;
            background-position: right center;
            box-sizing: inherit;
            padding-left: 1rem;
            box-sizing: content-box;
          }
          &.accordion-button:not(.collapsed) {
            box-shadow: none;
            background: transparent;
            &::after {
              background-image: url("../images/ic_notCollapsed.png");
              transform: rotate(-180deg);
            }
          }
          &::after {
            background-image: url("../images/ic_collapsed.png");
          }
        }
        .accordion-body {
          background: transparent;
          color: var(--bs-dark);
          font-weight: 400;
          font-size: 1.125rem;
          line-height: 1.8;
          padding: 0 2rem 2rem;
        }
      }
    }
  }
}

// 404 에러 페이지
#not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .row {
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 2.5rem;
      font-weight: 700;
    }
    h3 {
      font-size: 1.5rem;
      margin-top: 2rem;
      font-weight: 400;
    }
    .btn-primary {
      margin-top: 4rem;
      padding: 0.65rem 3rem;
      border-radius: 100px;
      font-weight: 700;
      font-size: 1.2rem;
    }
  }
}

// HOVER
@media (min-width: 1025px) {
  @media (hover: hover) {
    .service {
      .bottom {
        section {
          &.section01 {
            .container {
              ul.words {
                li {
                  span {
                    &:hover {
                      background: var(--bs-primary);
                      color: var(--bs-white);
                      &:after {
                        transform: scaleX(0);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    #svc-uptalk {
      .nav-tabs {
        .nav-item {
          .nav-link {
            &:hover {
              border: none;
            }
          }
        }
      }
      section.questions {
        button {
          &:hover {
            background-color: #5787ff;
          }
        }
      }
    }
    #works-list {
      .container {
        .title-area {
          .nav-list {
            .form-check {
              &:hover {
                label {
                  background-color: var(--bs-primary-light);
                  border-color: var(--bs-primary-light);
                  color: var(--bs-gray-700);
                }
              }
            }
          }
        }
      }
      ul.list-group {
        li.works-item {
          &:hover {
            .inner {
              figure {
                background-size: auto 110%;
              }
              .inner-border {
                border-width: 6px;
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }

    #works-detail {
      .panel {
        &.bottom {
          section {
            &.next-work {
              .btn-list {
                &:hover {
                  opacity: 0.8;
                }
              }
              .work-info {
                &:hover {
                  button {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }

    #news-list {
      ul.list-group {
        li.news-item {
          &:hover {
            .inner {
              figure {
                background-size: auto 110%;
              }
              &:after {
                border-width: 6px;
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }

    #news-detail {
      .news-wrap {
        .news-content {
          .btn-area {
            button {
              &.btn-list {
                &:hover {
                  color: var(--bs-primary);
                  border-color: var(--bs-primary);
                }
              }
            }
          }
        }
      }
    }
  }
}

// ***********************************
// PC HEIGHT 관련
// ***********************************
// -- WIDTH 1024 이상
@media (min-width: 1024px) {
  // HEIGHT 850이하
  @media (max-height: 850px) {
    .desktopDevice {
      #svc-wallet {
        .bottom {
          section {
            &.section02 {
              .container {
                .change-panel-wrap {
                  .change-panel-list {
                    height: 40vh;
                  }
                  .keywords {
                    top: 80vh;
                    & > div {
                      width: 130px;
                      height: 130px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      #svc-uptalk {
        section {
          &.visual {
            .main-img {
              max-width: 1000px;
              left: calc(50% - 500px);
              top: calc(100vh - 496px);
            }
          }
          &.text-area {
            padding-top: 620px;
          }
        }
      }
    }
  }
  // HEIGHT 750이하
  @media (max-height: 750px) {
    .desktopDevice {
      #svc-wallet {
        .bottom {
          section {
            &.section02 {
              .container {
                .change-panel-wrap {
                  padding: 40px 0 0 !important;
                  .change-panel-list {
                    height: 50vh;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// -- 전체 HEIGHT 750 이하
@media (max-height: 750px) {
  .desktopDevice {
    #works-detail {
      & > .mockup-box {
        width: 640px;
        top: calc(84vh - 410px);
        right: calc(50% - 650px);
        &.tablet {
          width: 500px;
          top: 20vh;
          right: calc(50% - 560px);
        }
        &.mobile {
          width: 320px;
          top: 20vh;
          right: calc(50% - 560px);
        }
      }
    }
    #svc-ts {
      .bottom {
        section {
          &.section02 {
            .text-center {
              max-width: 100%;
            }
          }
          &.section03 {
            .container {
              .change-panel-wrap-01 {
                .change-panel-list {
                  height: 46vh;
                }
              }
            }
          }
          &.section04 {
            .container {
              .change-panel-wrap-02 {
                .circle-wrap {
                  height: 46vh;
                }
              }
            }
          }
        }
      }
    }
    #svc-wallet {
      .bottom {
        section {
          &.section02 {
            .container {
              .change-panel-wrap {
                .change-panel-list {
                  height: 46vh;
                }
              }
            }
          }
        }
      }
    }
  }
}
// -- 전체 HEIGHT 700 이하
@media (max-height: 700px) {
  #svc-ts {
    .bottom {
      section {
        &.section02 {
          .text-center {
            max-width: 100%;
          }
        }
        &.section03 {
          .container {
            .change-panel-wrap-01 {
              .change-panel-list {
                height: 60vh;
              }
              .keywords {
                display: none;
              }
            }
          }
        }
        &.section04 {
          .container {
            .change-panel-wrap-02 {
              .circle-wrap {
                height: 64vh;
              }
              .range-area {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  #svc-wallet {
    .bottom {
      section {
        &.section02 {
          .container {
            .change-panel-wrap {
              .change-panel-list {
                height: 60vh;
              }
              .keywords {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
// -- WIDTH 1024 이하
@media (max-width: 1024px) {
  .App.smallScreen {
    .desktopDevice {
      // -- UPTS
      #svc-ts {
        .bottom {
          section {
            &.section04 {
              .container {
                .change-panel-wrap-02 {
                  .circle-wrap {
                    .circle {
                      top: 4vh;
                      left: calc(50% - 20vw);
                      width: 40vw;
                      height: 40vw;
                    }
                  }
                }
              }
            }
          }
        }
      }
      // -- UP WALLET
      #svc-wallet {
        .bottom {
          section {
            &.section02 {
              .container {
                .change-panel-wrap {
                  .change-panel-list {
                    height: 50vh !important;
                  }
                }
              }
            }
          }
        }
      }
      // -- UP T3
      #svc-t3 {
        .panel {
          &.bottom {
            section {
              &.section01 {
                .container {
                  .change-panel-wrap {
                    padding: 6rem 0 0 !important;
                    .change-panel-list {
                      height: 72vh;
                      article {
                        & > * {
                          &.img-box {
                            flex-basis: 300px;
                            width: 300px;
                            height: 300px;
                          }
                        }
                      }
                    }
                  }
                }
              }
              &.section03 {
                .container {
                  .change-panel-wrap {
                    .change-panel-list {
                      .change-panel-02-box {
                        width: 68vh;
                        height: 68vh;
                        position: inherit;
                        top: inherit;
                        left: inherit;
                      }
                      article {
                        & > div {
                          strong {
                            font-size: 1rem;
                          }
                          p {
                            font-size: 0.8rem;
                            line-height: 1.6;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // -- HMPS
      #svc-hmps {
        .panel {
          &.visual {
            .parallax-bg {
            }
            .container {
              .typo {
                // padding-top: 4vh;
              }
            }
          }
          &.bottom {
            section {
              &.section01,
              &.section02 {
                .change-panel-wrap {
                  .change-panel-list {
                    margin: 2rem 0 0;
                    article {
                      .container {
                        & > div {
                          &.img-box {
                          }
                          &.text-box {
                            h3 {
                              font-size: 1.15rem;
                            }
                            p {
                              font-size: 0.9rem;
                            }
                          }
                        }
                      }
                    }
                    .dot-box {
                      i {
                        margin: 0 0.15rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// -- WIDTH 640 이하
@media (max-width: 640px) {
  .App.smallScreen {
    .desktopDevice {
      // -- UP T3
      #svc-t3 {
        .panel {
          &.bottom {
            section {
              &.section03 {
                .container {
                  .change-panel-wrap {
                    .change-panel-list {
                      .change-panel-02-box {
                        width: 116vw;
                        height: 116vw;
                        position: absolute;
                        top: calc(50% - 58vw);
                        left: calc(50% - 58vw);
                        & > div {
                          width: 86%;
                          & > figure {
                            width: 110px;
                            height: 110px;
                          }
                          &.box-03 {
                            top: -55px;
                            left: calc(50% - 55px);
                            height: calc(100% + 110px);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
