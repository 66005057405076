// ***********************************
// MOBILE //갤럭시폴드 확인되야함
// ***********************************
@media (max-width: 640px) {
  // *******************
  // common.scss
  // *******************
  html {
    font-size: 4vw;
  }

  // GOSCROLLTOP
  #progress-circle {
    background-size: 16px auto;
    svg {
      width: 44px;
      height: 44px;
      circle {
        stroke-width: 2;
      }
    }
  }

  // LOADING
  .loading-inner {
    svg {
      height: 32px;
    }
  }

  .Toastify__toast-container {
    width: 80vw;
    top: 70px;
  }

  // APP-CONTAINER
  .App.smallScreen {
    .app-container {
      // 스크롤 내려갈 때
      &.scroll-down {
        header {
          top: -70px;
        }
      }

      // 메뉴 열렸을 때
      &.open-menu {
        header {
          .container {
            .btn_menu {
              svg {
                rect {
                  width: 24px;
                  &:nth-of-type(1) {
                    transform: translate(-1px, 16px) rotate(-45deg);
                  }
                  &:nth-of-type(3) {
                    transform: translate(-1px, -16px) rotate(45deg);
                  }
                }
              }
            }
          }
        }
      }
    }

    // HEADER
    header {
      height: 60px;
      .btn_menu {
        svg {
          width: 22px;
          height: 22px;
          rect {
            &:nth-of-type(1) {
              y: 4px;
            }
            &:nth-of-type(2) {
              y: 10px;
            }
            &:nth-of-type(3) {
              y: 16px;
            }
          }
        }
        &:before {
          width: 12vw;
          height: 12vw;
          top: calc(50% - 6vw);
          left: calc(50% - 6vw);
        }
      }
    }

    footer {
      .container {
        .top {
          .sns-list {
            li {
              margin-right: 0.75rem;
              a {
                width: 18px;
                height: 18px;
                background-size: auto 16px;
              }
            }
          }
        }
      }
    }

    // *******************
    // pages.scss
    // *******************
    #home {
      section {
        &#visual {
          .typo {
            h1 {
              font-size: 9vw;
            }
          }
        }
        .mainTxt {
          .badge {
            font-size: 1rem;
          }
          h2 {
            font-size: 1.4rem;
          }
        }
        .works-box {
          .works {
            padding-bottom: 50vh;
          }
        }
        .puzzle {
          padding-bottom: 40vh;
        }
        &.chart-section {
          padding-top: 1rem;
          .chart {
            margin-top: 1rem;
            svg {
              &.chartBg {
                right: -112vw;
                bottom: -10vh;
              }
            }
          }
        }
      }
    }
    // ABOUT (회사소개)
    #about {
      .panel {
        &.visual {
          section {
            .typo {
              margin-top: -34vh;
            }
          }
        }
      }
    }
    // --service
    .service {
      .bottom {
        section {
          &.section01 {
            .container {
              ul.words {
                margin-left: -38vw;
                li {
                  span {
                    padding: 0 1rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    // -- UPTS
    #svc-ts {
      .bottom {
        section {
          &.section03 {
            .container {
              .change-panel-wrap-01 {
                .change-panel-list {
                  height: 60vh;
                  article {
                    p {
                      padding: 0;
                    }
                  }
                }
              }
            }
          }
          &.section04 {
            .container {
              .change-panel-wrap-02 {
                padding: 4.5rem 1.5rem 0 !important;
                .circle-wrap {
                  .circle {
                    width: 70vw;
                    height: 70vw;
                    left: calc(50% - 35vw);
                    strong {
                      padding-top: 0.5rem;
                      font-size: 0.9rem;
                    }
                    &.active {
                      strong {
                        font-size: 1.4rem;
                      }
                    }
                  }
                }
                .range-area {
                  .range-text {
                    li {
                      font-size: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // -- UP WALLET
    #svc-wallet {
      .bottom {
        section {
          &.section02 {
            .container {
              .change-panel-wrap {
                .change-panel-list {
                  height: 48vh;
                  article {
                    img {
                      height: 28vh;
                    }
                    p {
                      max-width: 100%;
                    }
                  }
                }
                .keywords {
                  top: 75vh;
                }
              }
            }
          }
        }
      }
    }
    // -- UP T3
    #svc-t3 {
      .panel {
        &.bottom {
          section {
            &.section01 {
              .title-bold-02 {
                font-size: 1.25rem;
              }
              .container {
                .change-panel-wrap {
                  padding: 6rem 0 0 !important;
                  .change-panel-list {
                    article {
                      & > * {
                        p {
                          padding: 0;
                        }
                        &.img-box {
                          flex-basis: 50vw;
                          width: 50vw;
                          height: 50vw;
                          margin: 0;
                          img {
                            width: 32vw;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &.section02 {
              .swiper-wrap {
                .swiper-t3 {
                  .swiper-wrapper {
                    .swiper-slide {
                      .img-box {
                        height: 110px;
                        img {
                          transform: scale(0.6);
                        }
                      }
                      h3 {
                        margin-top: 1rem;
                        font-size: 1.15rem;
                      }
                    }
                  }
                }
              }
            }
            &.section03 {
              .container {
                .change-panel-wrap {
                  padding: 5rem 0 0 !important;
                  .change-panel-list {
                    height: 75vh;
                    margin-top: 1.5rem;
                    .change-panel-02-box {
                      width: 116vw;
                      height: 116vw;
                      position: absolute;
                      top: calc(50% - 58vw);
                      left: calc(50% - 58vw);
                      border-width: 1px;
                      & > div {
                        width: 86%;
                        & > figure {
                          width: 80px;
                          height: 80px;
                          transform: scale(0.7);
                        }
                        &.box-01 {
                          top: 2%;
                          left: 7%;
                        }
                        &.box-02 {
                          bottom: 2%;
                          left: 7%;
                        }
                        &.box-03 {
                          width: auto;
                          top: -40px;
                          left: calc(50% - 40px);
                          height: calc(100% + 80px);
                        }
                        &.active {
                          & > figure {
                            transform: scale(1);
                          }
                        }
                      }
                    }
                    article {
                      & > div {
                        width: 75vw;
                        height: 75vw;
                        &:after {
                          width: 80%;
                          height: 80%;
                          left: 10%;
                        }
                      }
                      &.change-01 {
                        & > div {
                          &:after {
                            top: -36vw;
                            border-right-width: 1px;
                            border-bottom-width: 1px;
                          }
                        }
                      }
                      &.change-02 {
                        & > div {
                          &:after {
                            bottom: -36vw;
                            border-left-width: 1px;
                            border-top-width: 1px;
                          }
                        }
                      }
                      &.change-03 {
                        & > div {
                          &:after {
                            width: 0;
                            height: 180%;
                            border-right-width: 1px;
                            left: calc(50% - 1px);
                            top: -40%;
                            transform: scale(0.9);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // -- UPTALK
    #svc-uptalk {
      section {
        // 비쥬얼
        &.visual {
          .container {
            .typo {
              top: 22vh;
            }
            .main-img {
              width: 83vw;
              bottom: 0;
              top: auto;
              left: 0;
              right: 0;
              margin: 0 auto;
            }
          }
        }
        // 텍스트 영역
        &.text-area {
          h5 {
            font-size: 1.5rem;
          }
        }
        // 탭 공통
        .tab-area {
          .nav-tabs {
            padding-bottom: 7rem;
            .nav-item {
              margin: 0 2px;
              .nav-link {
                h5.title {
                  font-size: 1rem;
                  height: 80px;
                }
                p {
                  padding: 0.5rem;
                }
              }
            }
          }
        }
      }
    }
    // -- HMPS
    #svc-hmps {
      .panel {
        &.bottom {
          section {
            &.section01,
            &.section02 {
              .change-panel-wrap {
                .change-panel-list {
                  article {
                    .container {
                      & > div {
                        &.img-box {
                          width: 70vw;
                          height: 70vw;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // WORKS (포트폴리오)
    // --DETAIL
    #works-detail {
      & > .mockup-box {
        top: 54vh;
        &.mobile {
          width: 70vw;
          right: 15vw;
        }
        &.tablet {
          width: 80vw;
          right: 10vw;
          & ~ .panel {
            &.bottom {
              section {
                &.overview {
                  padding-top: 15vh;
                }
              }
            }
          }
        }
      }
      .panel {
        &.bottom {
          section {
            padding: 5rem 0;
            .content {
              margin-top: 8vh;
            }
            &.overview {
              section {
                .content {
                  flex-direction: column;
                  & > h2 {
                    padding-right: 30%;
                  }
                }
              }
            }
            &.next-work {
              .container {
                .btn-list {
                  height: 32px;
                  line-height: 32px;
                }
              }
            }
          }
        }
      }
    }

    // WORKS (포트폴리오)
    // --LIST
    #works-list {
      .container {
        ul.list-group {
          li.works-item {
            .inner {
              .row {
                h5 {
                  font-size: 1.1rem;
                }
              }
            }
          }
        }
      }
    }

    // CONTACT (프로젝트 문의)
    #contact {
      .title-area {
        text-align: center;
        h5 {
          margin-top: 1rem;
          font-size: 1rem;
        }
        h2 {
          font-size: 1.4rem;
        }
      }
      .form-wrap {
        margin: 0;
      }
      .information {
        margin-top: 4rem;
        padding: 0;
        background: transparent;
        border-radius: 0;
        div {
          margin-top: 3rem;
          h5 {
            font-size: 1.1rem;
            margin-bottom: 0;
          }
          strong {
            font-size: 2.4rem;
            line-height: 1.6;
            font-weight: 700;
          }
          dl {
            &:first-of-type {
              margin-top: 1rem;
            }
            margin-top: 0.5rem;
            flex-direction: column;
            align-items: stretch;
            dt {
              flex: auto 0 0;
              font-size: 1rem;
              margin-bottom: 0.25rem;
              font-weight: 500;
            }
            dd {
              font-weight: 400;
              font-size: 1rem;
            }
          }
        }
      }
      #faq {
        .accordion-flush {
          .accordion-item {
            .accordion-button {
              padding: 1.25rem;
            }
            .accordion-body {
              padding: 0 1.25rem 1.25rem;
            }
          }
        }
      }
    }
  }
}
